import { endpoints } from '../utilities/config'

async function getAllVideos(params) {
  const url = endpoints.videos.getAll.replace(':userId', params.userId)
  const response = await fetch(url, {
    method: 'GET'
  })
  return response.json()
}

export {
  getAllVideos
}