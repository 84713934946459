import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MotiLogoWhite from 'images/moti-logo-white'
import MotiLogoBlack from 'images/moti-logo-black'
import MotiLogoFinal from 'images/MOTI_Logo.png'
import { clearStorage } from '../helpers/appHelpers'

class Navbar extends Component {

	state = {
		collapsed: true,
	};

	toggleNavbar = () => {
		this.setState({
			collapsed: this.state.collapsed,
		});
	}

	componentDidMount() {
		const { isDefaultSticky } = this.props
		this._isMounted = true;
		let elementId = document.getElementById("navbar");
		if (isDefaultSticky) return elementId.classList.add("is-sticky")
		document.addEventListener("scroll", () => {
			if (window.scrollY > 170) {
				elementId.classList.add("is-sticky");
			} else {
				elementId.classList.remove("is-sticky");
			}
		});
		window.scrollTo(0, 0);

		// this.menuActiveClass()
	}

	menuActiveClass = () => {
		let mainNavLinks = document.querySelectorAll("nav ul li a");
		window.addEventListener("scroll", () => {
			let fromTop = window.scrollY;
			mainNavLinks.forEach(link => {
				if (link.hash) {
					let section = document.querySelector(link.hash);

					if (
						section.offsetTop <= fromTop &&
						section.offsetTop + section.offsetHeight > fromTop
					) {
						link.classList.add("active");
					} else {
						link.classList.remove("active");
					}
				}
			});
		});
	}

	signOut = () => {
		clearStorage()
	}

	render() {
		const { collapsed } = this.state;
		const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
		const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
		const { isLoggedIn } = this.props

		return (
			<div>
				<nav id="navbar" className="navbar navbar-expand-lg navbar-style-one navbar-light bg-light">
					<div className="container">
						<Link to="/agency" className="navbar-brand">
							<img src={MotiLogoBlack} className="moti-brand" alt="logo" />
							<img src={MotiLogoBlack} className="moti-brand" alt="logo" />
						</Link>

						<button
							onClick={this.toggleNavbar}
							className={classTwo}
              style={{zIndex: 99}}
							type="button"
							data-toggle="collapse"
							data-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className={classOne} id="navbarSupportedContent">
							{ isLoggedIn ?
								<ul className="navbar-nav ms-auto">
									<li className="nav-item">
										<Link to="/dashboard" className="nav-link">Dashboard</Link>
									</li>
									<li className="nav-item">
										<Link to="/my-meta-data" className="nav-link">Music Meta Data</Link>
									</li>
									{/* <li className="nav-item">
										<AnchorLink
											onClick={this.toggleNavbar}
											offset={() => 100}
											className="nav-link"
											href="#home"
										>
											Learning Material
										</AnchorLink>
									</li> */}
									<li className="nav-item">
										<Link to="/splits/new" className="nav-link">Split Sheets</Link>
									</li>
									<li className="nav-item">
										<Link to="/ask-moti" className="nav-link">Ask MOTI</Link>
									</li>
									<li className="nav-item">
										<Link to="/users/profile" className="nav-link">Profile</Link>
									</li>
									<li className="nav-item">
										<Link onClick={this.signOut} to="/" className="nav-link">Sign out</Link>
									</li>
								</ul>
								:							 
								<ul className="navbar-nav ms-auto">
									<li className="nav-item">
										<Link to="/" className="nav-link">Home</Link>
									</li>
									<li className="nav-item">
										<Link to="/about" className="nav-link">About</Link>
									</li>
									<li className="nav-item">
										<Link to="/videos" className="nav-link">Videos</Link>
									</li>
									<li className="nav-item">
										<Link to="/pricing" className="nav-link">Pricing</Link>
									</li>
									<li className="nav-item">
										<Link to="/news" className="nav-link">Socials</Link>
									</li>
									{/* <li className="nav-item">
										<Link to="/team" className="nav-link">The Team</Link>
									</li> */}
									<li className="nav-item">
										<Link to="/coming-soon" className="nav-link">Coming Soon</Link>
									</li>
									<li className="nav-item">
										<Link to="/contact" className="nav-link">Contact</Link>
									</li>
									<li className="nav-item">
										<Link to="/signin" className="nav-link">Sign in</Link>
									</li>
								</ul>
							}
							{/* {
								isLoggedIn ?
								<ul className="navbar-nav ms-auto">
                  <li className="nav-item">
										<Link onClick={this.signOut} to="/" className="nav-link">Sign out</Link>
									</li>
								</ul>
								:
								<ul className="navbar-nav ms-auto">
                  <li className="nav-item">
										<Link to="/signin" className="nav-link">Sign in</Link>
									</li>
								</ul>
							} */}
						</div>
					</div>
				</nav>
			</div>
		);
	}
}

export default Navbar;
