import React from 'react'
import LandingPage from './views/landingPage'
import AboutPage from './views/aboutPage'
import VideosPage from './views/videosPage'
import PricingPage from './views/pricingPage'
import NewsPage from './views/newsPage'
import TeamPage from './views/teamPage'
import ComingSoonPage from './views/comingSoonPage'
import ContactPage from './views/contactPage'
import SigninPage from './views/signinPage'
import SignupPage from './views/signupPage'
import DashboardPage from './views/dashboardPage'
import MetaDataPage from './views/metaDataPage'
import NewSplitPage from './views/newSplitPage'
import SuccessPage from './views/successPage'
import MasterclassPage from './views/masterclassPage'
import UserProfilePage from './views/userProfilePage'
import SendPasswordRecoveryPage from './views/sendPasswordRecoveryPage'
import ResetPasswordPage from './views/resetPasswordPage'
import AskMotiPage from './views/askMotiPage'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
require('dotenv').config()

const App = (props) => {
  return(
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/videos" component={VideosPage} />
        <Route exact path="/pricing" component={PricingPage} />
        <Route exact path="/news" component={NewsPage} />
        <Route exact path="/team" component={TeamPage} />
        <Route exact path="/coming-soon" component={ComingSoonPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/signin" component={SigninPage} />
        <Route exact path="/signup" component={SignupPage} />
        <Route exact path="/dashboard" component={DashboardPage} />
        <Route exact path="/my-meta-data" component={MetaDataPage} />
        <Route exact path="/splits/new" component={NewSplitPage} />
        <Route exact path="/users/profile" component={UserProfilePage} />
        <Route exact path="/password/reset" component={SendPasswordRecoveryPage} />
        <Route exact path="/password/new" component={ResetPasswordPage} />
        <Route exact path="/ask-moti" component={AskMotiPage} />
        <Route exact path="/success" component={SuccessPage} />
        {/* <Route exact path="/videos/masterclasses" component={MasterclassPage} /> */}
      </Switch>
    </Router>
  )
}

export default App