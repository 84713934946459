import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Success extends Component {
  render() {
    return (
      <section id="success" className="success-area">
        <div className="status-card ptb-100">
          <div className="icon-wrapper">
            <i className="checkmark">✓</i>
          </div>
          <h1>Success</h1>
          <p>We have received your payment</p>
          <br />
          <p>Please check your email</p>
          <p>Once you receive an email from us confirming receipt of your payment, <br />you will be able to browse our paid content</p>
          <br />
          <p>This email usually takes about 20 seconds to arrive</p>
          <br />
          <br />
          <Link to="/dashboard" className="btn btn-primary">
            Lets Go
          </Link>
        </div>
      </section>
    );
  }
}

export default Success;
