import React, { Component } from 'react';
import AboutImage from "images/logo.png"
import CirleImage from "images/circle.png"
class About extends Component {
	render() {
		return (
			<section id="about" className="about-area ptb-100">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12">
							<div className="about-image">
								<img src={AboutImage} alt="image" />
							</div>
						</div>

						<div className="col-lg-6 col-md-12">
							<div className="about-content pl-15">
								<h2 className="about-heading">ABOUT</h2>
								<p>What Is Masters Of The Industry #MOTI</p>
								<br/>
								<p>
								  Masters Of The Industry aka M.O.T.I is an online skills-sharing platform and 
								  resource that addresses the fact that basic information on the music industry 
                  is not readily available to the creative sector. What the African continent 
                  is not short of is talent but what it is lacking are the basic business 
                  knowledge that is necessary to making it as a successful creative. 
								</p>
								<br/>
								<p>
                  Our platform provides robust yet easy-to-understand music industry information through
                  step by step videos for anyone wanting to enter the music industry or better formalise 
                  and understand the mechanics necessary to maintaining a sustainable and lucrative career.
                </p>
                <br/>
                <p>
                Our resources introduce the "MOTI Digital Split Sheet" and "Metadata Form", two of 
                which are essential tools which we believe ought to be easily accessible 
                in order to be completed as soon as a song is made, avoiding possible future 
                conflicts surrounding ownership, royalties and money.
                </p>
								<a href="/signup" className="btn btn-primary btn-orange">Sign Up Here</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default About;
