import React, { useEffect, useState } from 'react'
import { Button, Modal, Input } from 'antd';
import { ShoppingCartOutlined, EditOutlined, FileAddOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const HeaderCard = (props) => {
  const { heading, body, imageUrl, downloadUrl, onActionPress, buttonTitle, buttonIcon, countDownDays, hideActionButton = false } = props
  const iconMapper = {
    manage: <EditOutlined />,
    subscribe: <ShoppingCartOutlined />,
    newSplitSheet: <FileAddOutlined />,
    question: <QuestionCircleOutlined />
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }} className='moti-header-wrapper'>
      <div className='ask-moti-header-left'>
        <img style={{ height: 'auto', width: 'auto' }} src={imageUrl} />
      </div>
      <div className='ask-moti-header-right'>
        <h2 className="text-dark font-weight-bold mb-2">{heading}</h2>
        <br />
        {body.map((text) => {
          return (
            <p style={{ fontWeight: 600 }}>{text}</p>
          )
        })}
        <br />
        {
          downloadUrl &&
          <a target="_blank" href={downloadUrl}>
            Download the M.O.T.I business plan template for free!
          </a>

        }
        {
          countDownDays && countDownDays > 0 ?
            <h4 className='subscription-countdown'>
              You have <span className='subscription-countdown-red'>{countDownDays} days</span> left on your subscription
            </h4> :
            ''
        }
        {
          !hideActionButton && onActionPress &&
          <div>
            <br />
            <Button type="primary" shape="round" size={'large'} className="btn btn-primary atn-button btn-with-icon" onClick={onActionPress}>
              {buttonIcon && iconMapper[buttonIcon]}
              {buttonTitle}
            </Button>
          </div>
        }
        < br />
      </div>
    </div>
  );
}

export default HeaderCard;