import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { getFromStorage } from '../helpers/appHelpers'
import { confirmAlert } from 'react-confirm-alert';
import { Redirect } from 'react-router-dom';
import { Avatar } from 'antd';
import ReactTimeAgo from 'react-time-ago';
import { Button, Modal, Input } from 'antd';
import HeaderCard from '../components/HeaderCard';
import { getAllQuestions, createQuestion } from '../services/questionService';
import { LoadingOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { Radio } from 'antd';

const { TextArea } = Input;
const TITLE_CHAR_LIMIT = 60;
const DESCRIPTION_CHAR_LIMIT = 120;

const AskMotiPage = (props) => {
  const [user, setUser] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [motiModalVisible, setMotiModalVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [isTitleValid, setIsTitleValid] = useState(false);
  const [question, setQuestion] = useState('');
  const [isQuestionValid, setIsQuestionValid] = useState(false);
  const [canSubmitForm, setCanSubmitForm] = useState(false);
  const [titleCharactersRemaining, setTitleCharactersRemaining] = useState(TITLE_CHAR_LIMIT)
  const [descriptionCharactersRemaining, setDescriptionCharactersRemaining] = useState(DESCRIPTION_CHAR_LIMIT)
  const [questions, setQuestions] = useState([]);
  const [isMyQuestionsLoading, setIsMyQuestionsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [preferredSocialPlatform, setPreferredSocialPlatform] = useState('');
  const [socialHandle, setSocialHandle] = useState('');
  const [isSocialHandleValid, setIsSocialHandleValid] = useState(false);

  useEffect(() => {
    const loggedInUser = getFromStorage('user')
    if (loggedInUser){
      setUser(loggedInUser)
    }
    else {
      setRedirect(true)
    }
  }, [user.firstName])

  useEffect( async () => {
    if (user && user.id){
      const params = { userId: user.id }
      setIsMyQuestionsLoading(true)
      const response = await getAllQuestions(params)
      setIsMyQuestionsLoading(false)
      if (response && response.ok){
        setQuestions(response.data)
      }
    }
  }, [user.firstName, JSON.stringify(questions)])

  useEffect(() => {
		const isFormValid = 
			isTitleValid &&
			isQuestionValid &&
      isSocialHandleValid
		setCanSubmitForm(isFormValid)
	}, [isTitleValid,
			isQuestionValid,
      isSocialHandleValid
		])

  function handleTitle(e) {
    const value = e.target.value
    const isValid = (value && value.length > 0) && (value.length <= TITLE_CHAR_LIMIT)
    setIsTitleValid(isValid)
    setTitleCharactersRemaining(TITLE_CHAR_LIMIT - value.length)
    setTitle(value)
  }

  function handleQuestion(e) {
    const value = e.target.value
    const isValid = (value && value.length > 0) && (value.length <= DESCRIPTION_CHAR_LIMIT)
    setIsQuestionValid(isValid)
    setDescriptionCharactersRemaining(DESCRIPTION_CHAR_LIMIT - value.length)
    setQuestion(value)
  }

  function handleSocialHandle(e) {
    const value = e.target.value
    const isValid = (value && value.length > 0)
    setIsSocialHandleValid(isValid)
    setSocialHandle(value)
  }

  function toggleSocialInput(e) {
    if(e && e.target && e.target.value) setPreferredSocialPlatform(e.target.value)
  }

  async function handleFormSubmission() {
    const params = {
      title: title,
      body: question,
      userId: user.id,
      socialPlatform: preferredSocialPlatform,
      socialHandle: socialHandle
    }
    setIsFormLoading(true)
    const response = await createQuestion(params)
    setIsFormLoading(false)

    if (response && response.ok){
      setMotiModalVisible(false)
      confirmAlert({
        title: 'Success',
        message: 'Your Question has been created successfully!',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              window.location.reload()
            }
          }
        ]
      })

    } else {
      setMotiModalVisible(false)
      confirmAlert({
        title: 'Error',
        message: response.error,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              setMotiModalVisible(true)
            }
          }
        ]
      })
    }
  }

  if (redirect) {
		return <Redirect push to="/" />
	}

  return (
    <div>
      <Navbar 
        isDefaultSticky={true}
        isLoggedIn={true}
      />
      <Modal
        centered
        visible={motiModalVisible}
        onOk={() => setMotiModalVisible(false)}
        onCancel={() => setMotiModalVisible(false)}
        width={700}
        footer={null}
      >
        <br/>
        <center>
          <h2>How Can We Help ?</h2>
        </center>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>Title</label>
                <p>{`${titleCharactersRemaining} characters`}</p>
              </div>
              <Input style={isTitleValid ? {} : {borderColor: '#B22222'}} value={title} onChange={handleTitle} className="form-control" placeholder="" />
              <p className='form-error-msg'>{!isTitleValid ? 'Please enter a valid Title' : ''}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>Description</label>
                <p>{`${descriptionCharactersRemaining} characters`}</p>
              </div>
              <TextArea style={isQuestionValid ? {} : {borderColor: '#B22222'}} value={question} onChange={handleQuestion} className="form-control" placeholder="" />
              <p className='form-error-msg'>{!isQuestionValid ? 'Please enter a valid Question' : ''}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>Where would you like us to answer your question</label>
              </div>
              <br/>
              <Radio.Group defaultValue="twitter" size="large" onChange={toggleSocialInput}>
                <Radio.Button value="Twitter">Twitter</Radio.Button>
                <Radio.Button value="Instagram">Instagram</Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </div>
        {
        preferredSocialPlatform &&
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>{`${preferredSocialPlatform} Handle`}</label>
              </div>
              <Input size="large" addonBefore="@" value={socialHandle} onChange={handleSocialHandle} className="" placeholder="" />
              <p className='form-error-msg'>{!isSocialHandleValid ? 'Please enter a valid handle' : ''}</p>
            </div>
          </div>
        </div>
        }
        <br/>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={!canSubmitForm} type="primary" shape="round" size={'large'} className="btn btn-primary" onClick={() => handleFormSubmission()}>
            Submit
          </Button>
        </div>
      </Modal>
      <section className="ask-moti-area">
        <HeaderCard
          heading='Ask M.O.T.I'
          body={
            ["Ask MOTI allows you to ask a Master of the Industry any question", "These questions will be answered every second Monday on Twitter Spaces and IG Live under the hashtag #MotiMondays"]
          }
          imageUrl='https://d3v0zkcslccxqy.cloudfront.net/logo.png'
          onActionPress={() => {
            setMotiModalVisible(true)
          }}
          buttonTitle={'Ask a Question'}
          buttonIcon={'question'}
        />
        < br/>
        < br/>
        < br/>
        < br/>
        <h3 className="text-dark font-weight-bold mb-2"> Popular Questions</h3>
        < br/>
        < br/>
        < br/>
        <div className="row">
          {
            [{
              name: 'Nthabiseng Ndlovu',
              initial: 'NN',
              title: 'What software can I use to make beats',
              description: 'As a newbie, what is the best and easiest software to create beats ?'
            },{
              name: 'Laiken Coetzee',
              initial: 'LC',
              title: 'Is getting signed worth it ?',
              description: 'Is the label approach still relavant these days ?'
            },{
              name: 'Russel Ncube',
              initial: 'RN',
              title: 'Who is MOTI ?',
              description: 'Who is the favourite MOTI ?'
            }].map((user, index) => {
              return (
                <div key={index} className='col-md-6 col-xl-4'>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='ask-moti-avatar-wrapper'>
                    <h4 style={{ color: '#fff' }}>{user.initial}</h4>
                  </div>
                  <div className='card card-social card-ask-moti ask-moti-wrapper'>
                    <p><span style={{fontWeight: 'bold'}}>{`${user.name} `}</span>asked</p>
                    <h3 style={{fontSize: 25}}>{user.title}</h3>
                    <h5 style={{fontSize: 18}} className="text-c-green mb-0">{user.description}</h5>
                    <br/>
                    <p>Created <ReactTimeAgo date={new Date(2021,8,6)} locale="en-US"/></p>
                  </div>
                  <br/>
                  <br/>
                </div>
              )
            })
          }
        </div>
        < br/>
        <h3 className="text-dark font-weight-bold mb-2"> My Questions</h3>
        < br/>
        < br/>
        < br/>
        <div className="row">
          { isMyQuestionsLoading ?
            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
              <LoadingOutlined style={{ fontSize: 24 }} spin />
              <br/>
              <br/>
              <br/>
            </div>
            :
            questions && questions.length ?
            questions.map((question, index) => {
              return (
                <div key={index} className='col-md-6 col-xl-4'>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='ask-moti-avatar-wrapper'>
                    <h4 style={{ color: '#fff' }}>{`${user.firstName[0]}${user.lastName[0]}`}</h4>
                  </div>
                  <div className='card card-social card-ask-moti ask-moti-wrapper'>
                    <p><span style={{fontWeight: 'bold'}}>{`${user.firstName} ${user.lastName} `}</span>asked</p>
                    <h3 style={{fontSize: 25}}>{question.title}</h3>
                    <h5 style={{fontSize: 18}} className="text-c-green mb-0">{question.body}</h5>
                    <br/>
                    <p>Created <ReactTimeAgo date={question.createdAt} locale="en-US"/></p>
                  </div>
                  <br/>
                  <br/>
                </div>
              )
            })
            :
            <div>
              <Empty description="You have not asked any questions yet"/>
              < br/>
              < br/>
              < br/>
              < br/>
            </div>
          }
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default AskMotiPage