import { endpoints } from '../utilities/config'

async function getVideoProgress(params) {
  const url = endpoints.videoProgress.get.replace(':userId', params.userId)
  const response = await fetch(url, {
    method: 'GET'
  })
  return response.json()
}

async function createVideoProgress(params) {
  const url = endpoints.videoProgress.create
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })
  return response.json()
}

async function updateVideoProgress(params) {
  const url = endpoints.videoProgress.update
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(params),
  })
  return response.json()
}

export {
  getVideoProgress,
  createVideoProgress,
  updateVideoProgress
}