import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../helpers/appHelpers';
import { Progress, Empty, Row } from 'antd';
import { Avatar, Tooltip } from 'antd';
import { UserOutlined, AntDesignOutlined } from '@ant-design/icons';
import ReactTimeAgo from 'react-time-ago'
import { Modal } from 'antd';
import NewSplitForm from './NewSplitForm';
import { emailSplitPdf } from '../services/splitService'
import { confirmAlert } from 'react-confirm-alert';

const Splits = (props) => {
  const { splits, user } = props
	const [split, setSplit] = useState({})
	const [splitModalVisible, setSplitModalVisible] = useState(false);
	const [isPdfLoading, setIsPdfLoading] = useState(false);

	const sendSplitPdf = async (params) => {
		setIsPdfLoading(true)
		const response = await emailSplitPdf(params)
		setIsPdfLoading(false)
		if (response && response.ok){
			confirmAlert({
        title: 'Success',
        message: `We have emailed your splitsheet document to ${user.emailAddress}`,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              window.location.reload()
            }
          }
        ]
      })
		} else {
			confirmAlert({
        title: 'Error',
        message: response.error,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {}
          }
        ]
      })
		}
	}

  return(
    <div className="mt-4">
      <div className="d-sm-flex mb-4 justify-content-between align-items-start">
        <h2 className="text-dark font-weight-bold mb-2"> My Splits</h2>
      </div>
      <div className="row">
        {	splits.length === 0 ?
					<div>
						<Empty description="No Splits"/>
						< br />
						< br />
						< br />
					</div>
					 :
					splits.map((split, index) => {
						return (
							<div key={index} className="col-md-6 col-xl-4">
								<div className="card card-social card-split">
										<div className="card-block border-bottom">
												<div className="row align-items-center justify-content-center">
														<div className="col-auto">
																{/* <i className="fas fa-music text-primary f-36"></i> */}
														</div>
														<div className="col text-left">
																<h3>{capitalizeFirstLetter(split.title)}</h3>
																<h5 className="text-c-green mb-0"><span className="text-muted">{capitalizeFirstLetter(split.genre)}</span></h5>
														</div>
												</div>
										</div>
										<div className="card-block">
												<div className="row align-items-center justify-content-center card-active">
														<div className="col-6">
																<h6 className="text-center m-b-10"><span className="text-muted m-r-5">My Share:</span>{split.metaData.myShare}%</h6>
																<Progress percent={split.metaData.myShare} status="active" type="line" showInfo={false} />
																{/* <div className="progress">
																		<div className="progress-bar progress-c-theme" role="progressbar" style={{ width:75, height:6 }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
																</div> */}
														</div>
														<div className="col-6">
																<h6 className="text-center  m-b-10"><span className="text-muted m-r-5">Other Share:</span>{split.metaData.otherShare}%</h6>
																<Progress percent={split.metaData.otherShare} type="line" showInfo={false} strokeColor="gray"/>
																{/* <div className="progress">
																		<div className="progress-bar progress-c-theme2" role="progressbar" style={{ width:25, height:6 }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
																</div> */}
														</div>
												</div>
												<center>
													{/* <div className="col-12 mt-4">
														<Link to="/dashboard" className="btn btn-primary theme-bg">View</Link>
													</div> */}
												</center>
										</div>
										<div style={{marginLeft: 20, marginBottom: 10}}>
											<p style={{margin: 0, padding: 0, marginBottom: 5}}>Composers:</p>
											<Avatar.Group
												maxCount={10}
												size="large"
												maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
											>
												{
													Object.values(split.composers).map((composer, index) => {
														return (
															<Tooltip title={`${composer.firstName} ${composer.lastName}: ${composer.percent}%`} color={composer.isActive ? '#3CB371' : '#f56a00'} key={index}>
																<Avatar key={index} style={{ backgroundColor: composer.isActive ? '#3CB371' : '#f56a00' }}>{composer.firstName[0].toUpperCase()}</Avatar>
															</Tooltip>
														)
													})
												}
												{/* <Avatar style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} /> */}
											</Avatar.Group>
										</div>
										<p style={{marginLeft: 20, marginBottom: 10}}>
											Created <ReactTimeAgo date={split.createdAt} locale="en-US"/>
										</p>
										<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
											{
												user && user.id === split.authorId && 
												<Link onClick={() => {
													setSplit(split)
													setSplitModalVisible(true)
												}} style={{marginLeft: 20, marginBottom: 10}}>
													Edit
												</Link>
											}
											{
												user && user.id !== split.authorId && !user.signatureUrlPath &&
												<Link onClick={() => {
													setSplit(split)
													setSplitModalVisible(true)
												}} style={{marginLeft: 20, marginBottom: 10}}>
													Sign Now
												</Link>
											}
											<Link onClick={() => sendSplitPdf({
												splitId: split.id,
												userId: user.id
											})} style={{marginRight: 20, marginBottom: 10}}>
												{ isPdfLoading ? '...Generating' : 'Download' }
											</Link>
										</div>
								</div>
							</div>
						)
					})
        }
    </div>
		<Modal
			centered
			visible={splitModalVisible}
			onOk={() => setSplitModalVisible(false)}
			onCancel={() => setSplitModalVisible(false)}
			width={1400}
			footer={false}
		>
			< NewSplitForm onSubmit={() => {setSplitModalVisible(false)}} selectedSplit={split} />
		</Modal>
  </div>
  )
}

export default Splits