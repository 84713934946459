import { endpoints } from '../utilities/config'

async function registerUser(params) {
  const url = endpoints.users.register

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })

  return response.json()
}

async function loginUser(params) {
  const url = endpoints.users.login

  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(params),
  })
  return response.json()
}

async function getUser(params) {
  const url = endpoints.users.get.replace(':userId', params.userId)

  const response = await fetch(url, {
    method: 'GET',
  })

  return response.json()
}

async function updateUser(params) {
  const url = endpoints.users.update

  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(params),
  })

  return response.json()
}

async function sendPasswordRecoveryEmail(params) {
  const url = endpoints.users.sendPasswordRecoveryEmail

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })

  return response.json()
}

async function resetPassword(params) {
  const url = endpoints.users.resetPassword

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })

  return response.json()
}

async function inviteFriend(params) {
  const url = endpoints.users.inviteFriend

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })

  return response.json()
}

export {
  registerUser,
  loginUser,
  getUser,
  updateUser,
  sendPasswordRecoveryEmail,
  resetPassword,
  inviteFriend
}