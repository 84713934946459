import { endpoints } from '../utilities/config'

async function createQuestion(params) {
  const url = endpoints.questions.create
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })
  return response.json()
}

async function getAllQuestions(params) {
  const url = endpoints.questions.getAll.replace(':userId', params.userId)
  const response = await fetch(url, {
    method: 'GET'
  })
  return response.json()
}

export {
  createQuestion,
  getAllQuestions
}