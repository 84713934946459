import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MotiLogoFinal from 'images/moti-logo-black.png'
import Map2 from "images/map2.png"

class Footer extends Component {
	render() {
		return (
			<footer className="footer-area bg-fffcf4">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-sm-6">
							<div className="single-footer-widget">
								<div className="logo">
									<Link to="/">
										<img style={{width: 'auto', height: 100}} src={MotiLogoFinal} alt="logo" />
									</Link>
								</div>

								<ul className="contact-info">
									{/* <li>31 Rivonia Road, Rivonia, Sandton, 2196</li> */}
									<li>
										<Link to="tel:+27798784268">
											+27798784268
										</Link>
									</li>
									<li>
										<Link to="mailto:support@genial.com">
											the.motiafrica@gmail.com
										</Link>
									</li>
									<li>
										<Link to="#">
											www.motiafrica.com
										</Link>
									</li>
								</ul>
							</div>
						</div>

						<div className="col-lg-2 col-sm-6">
							<div className="single-footer-widget pl-3">
								<h3>Quick Links</h3>

								<ul className="list">
									<li>
										<Link to="/">
											Home
										</Link>
									</li>
									{/* <li>
										<Link to="/">
											Features
										</Link>
									</li>
									<li>
										<Link to="/">
											Screenshots
										</Link>
									</li>
									<li>
										<Link to="/">
											Pricing
										</Link>
									</li>
									<li>
										<Link to="/">
											News
										</Link>
									</li> */}
								</ul>
							</div>
						</div>

						<div className="col-lg-3 col-sm-6">
							<div className="single-footer-widget pl-5">
								<h3>Support</h3>

								<ul className="list">
									{/* <li>
										<Link to="#">
											Quick Support
										</Link>
									</li> */}
									{/* <li>
										<Link to="#">
											Privacy Policy
										</Link>
									</li> */}
									{/* <li>
										<Link to="#">
											Copyright
										</Link>
									</li> */}
									<li>
										<a href="https://d3v0zkcslccxqy.cloudfront.net/popia-moti.pdf" target="_blank">
											POPIA Policy
										</a>
									</li>
									{/* <li>
										<Link to="#">
											Testimonials
										</Link>
									</li> */}
								</ul>
							</div>
						</div>

						{/* <div className="col-lg-3 col-sm-6">
							<div className="single-footer-widget">
								<h3>My Account</h3>

								<ul className="list">
									<li>
										<Link to="#">
											Managed Account
										</Link>
									</li>
									<li>
										<Link to="#">
											Create Account
										</Link>
									</li>
									<li>
										<Link to="#">
											Support Center
										</Link>
									</li>
								</ul>
							</div>
						</div> */}
					</div>
				</div>

				<div className="copyright-area">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-7 col-md-7">
								<p><i className="far fa-copyright"></i> 2021 MotiAfrica. All Rights Reserved</p>
							</div>

							<div className="col-lg-5 col-md-5">
								<ul>
									<li>
										<a href="https://www.facebook.com/MotiAfrica" target="_blank">
											<i className="fab fa-facebook-f"></i>
										</a>
									</li>
									<li>
										<a href="https://twitter.com/Moti_Africa" target="_blank">
											<i className="fab fa-twitter"></i>
										</a>
									</li>
									<li>
										<a href="https://www.instagram.com/moti_africa/" target="_blank">
											<i className="fab fa-instagram"></i>
										</a>
									</li>
									{/* <li>
										<a href="https://www.linkedin.com/" target="_blank">
											<i className="fab fa-linkedin-in"></i>
										</a>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="map2">
					<img src={Map2} alt="map" />
				</div>
			</footer>
		);
	}
}

export default Footer;
