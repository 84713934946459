import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import { Redirect } from 'react-router-dom';
import { Input } from 'antd';
import { Button } from 'antd';
import {
	validateEmail
} from '../utilities/validators';
import { sendPasswordRecoveryEmail } from '../services/userService'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { setInStorage } from '../helpers/appHelpers';
import { getFromStorage } from '../helpers/appHelpers'

const SendPasswordRecoveryPage = (props) => {
	const [email, setEmail] = useState('')
	const [isEmailValid, setIsEmailValid] = useState(false)
	const [isFormLoading, setIsFormLoading] = useState(false)
	const [canSubmitForm, setCanSubmitForm] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [loggedInRedirect, setLoggedInRedirect] = useState(false)

	useEffect(() => {
		const isFormValid = 
			isEmailValid
		setCanSubmitForm(isFormValid)
	}, [isEmailValid])

	useEffect(() => {
		const loggedInUser = getFromStorage('user')
		if (loggedInUser){
      setLoggedInRedirect(true)
    }
    else {
			// do nothing
    }
	}, [])

	function handleEmail(e) {
		const value = e.target.value.toLowerCase()
		const isValid = validateEmail(value)
		setIsEmailValid(isValid)
		setEmail(value)
	}

	async function handleFormSubmission() {
		const formParams = {
			emailAddress: email
		}

		setIsFormLoading(true)
		const response = await sendPasswordRecoveryEmail(formParams)
		setIsFormLoading(false)

		if (response && response.ok){
			confirmAlert({
				title: 'Success',
				message: 'Please check your email for further instructions on how to reset your password',
				buttons: [
					{
						label: 'Ok',
						onClick: () => {}
					}
				]
			})
		}
		
		else {
			confirmAlert({
				title: 'Error',
				message: response.error,
				buttons: [
					{
						label: 'Ok',
						onClick: () => {}
					}
				]
			})
		}
	}

	if (loggedInRedirect) {
		return <Redirect push to="/dashboard" />
	}

	return (
		<section className="login-area">
			<div className="d-table">
				<div className="d-table-cell">
					<div className="login-form">
						<h3>Password Reset</h3>

						<form>
							<div className="form-group">
								<label>Email</label>
								<Input style={isEmailValid ? {} : {borderColor: '#B22222'}} value={email} onChange={handleEmail} className="form-control" placeholder="" />
								<p className='form-error-msg'>{!isEmailValid ? 'Please enter a valid Email' : ''}</p>
							</div>

							<br/>
							<Button disabled={!canSubmitForm} type="primary" shape="round" size={'large'} className="btn btn-primary atn-button" loading={isFormLoading} onClick={handleFormSubmission}>
            		Send
          		</Button>

							<p>
								<a href="/signin" className="pull-left">Existing User ? Login</a>
							</p>
						</form>
					</div>
				</div>
			</div>
			< Footer/>
		</section>
	);
}

export default SendPasswordRecoveryPage;
