function validateUsername(value) {
  /* 
    Usernames can only have: 
    - Lowercase Letters (a-z) 
    - Numbers (0-9)
    - Dots (.)
    - Underscores (_)
  */
  const res = /^[a-zA-Z0-9_\.]+$/.exec(value);
  const valid = !!res && value.length > 3;
  return valid
}

function validateName(value) {
  const res = /^[a-z ,.'-]+$/i.exec(value);
  const valid = !!res && value.length > 1;
  return valid
}

function validateEmail(value) {
  const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(value);
  const valid = !!res && value.length > 1;
  return valid
}

function validatePassword(value) {
  // const res = /^[a-zA-Z0-9]{6,}$/.exec(value);
  const valid = value && value.length > 6;
  return valid
}

function validateSongTitle(value) {
  const valid = value && value.length > 1;
  return valid
}

function validateGenre(value) {
  const genres = [
    "afro-beat",
    "afrobeats",
    "afro-folk",
    "afro-fusion",
    "afro-house",
    "afro-pop",
    "afro-soul",
    "african-dancehall",
    "african-reggae",
    "afrikaans",
    "alte",
    "amapiano",
    "benga",
    "bongo-flava",
    "classical",
    "coup-de-cale",
    "country",
    "dancehall",
    "electronic-dance",
    "folk",
    "gosepl",
    "gqom",
    "highlife",
    "hip-hop",
    "hip-life",
    "house",
    "jazz",
    "kizomba",
    "kuduro",
    "kwaito",
    "makossa",
    "maskandi",
    "mbalax",
    "mbaqanga",
    "ndombolo",
    "other",
    "pop",
    "reggae",
    "rnb",
    "rock",
    "shangaan-electro",
    "soul",
    "soukous",
    "taarab",
    "zouglou"
  ]
  const valid = genres.includes(value)
  return valid
}

export {
  validateUsername,
  validateName,
  validateEmail,
  validatePassword,
  validateSongTitle,
  validateGenre
}