import { getUser } from '../services/userService'
import moment from 'moment';

function setInStorage(obj) {
  let { key, value } = obj
  key = `moti_${key}`
  localStorage.setItem(key, JSON.stringify(value))
}

async function getLatestUser(loggedInUser) {
  const response = await getUser({ userId: loggedInUser.id })
  if (response && response.ok) {
    const user = response.data
    setInStorage({
      key: 'user',
      value: user
    })
  }
}

function getFromStorage(key) {
  key = `moti_${key}`
  const value = localStorage.getItem(key)
  return JSON.parse(value)
}

function removeFromStorage(key) {
  key = `moti_${key}`
  localStorage.removeItem(key)
}

function clearStorage() {
  localStorage.clear();
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function subscriptionDaysLeft(user) {
  let daysLeft;
  if (user && !user.isPayingCustomer && user.subscriptionEnd && user.nextPaymentDate) {
    const subscriptionEnd = moment(user.subscriptionEnd);
    const subscriptionNextBilling = moment(user.nextPaymentDate);
    const today = moment()
    daysLeft = subscriptionNextBilling.diff(today, 'days');
  } else {
    daysLeft = 0;
  }
  return daysLeft;
}

function isPayingCustomer(user) {
  // if (user && (user.isPayingCustomer || user.isPromotionActive)) 
  if (true) {
    return true
  }
  else if (user && subscriptionDaysLeft(user) > 0) {
    return true
  } else {
    return false
  }
}

export {
  setInStorage,
  getFromStorage,
  removeFromStorage,
  clearStorage,
  capitalizeFirstLetter,
  getLatestUser,
  isPayingCustomer,
  subscriptionDaysLeft
}