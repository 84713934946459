import { endpoints, envKeys } from '../utilities/config'

async function createEditLink(params) {
  const url = endpoints.billing.createEditLink.replace(':code', params.subscriptionId)
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${envKeys.payStackSecretKey}` }, // To Do
  })
  return response.json()
}

export {
  createEditLink
}