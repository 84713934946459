import React, { useEffect, useState, useRef } from 'react';
import { Typography } from 'antd';
import { Modal, Input, Button, InputNumber } from 'antd';
import { Select, Form, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createSplit, updateSplit } from '../services/splitService'
import { confirmAlert } from 'react-confirm-alert';
import { PoweroffOutlined } from '@ant-design/icons';
import { getFromStorage } from '../helpers/appHelpers';
import { Redirect } from 'react-router-dom';
const { Title } = Typography;
import { setInStorage } from '../helpers/appHelpers';
import SignatureCanvas from 'react-signature-canvas'
import { cloudFrontUrls, envKeys } from '../utilities/config';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
const client = new S3Client({ 
  region: envKeys.awsRegion, 
  credentials: { 
    accessKeyId: envKeys.awsAccessKeyId,
    secretAccessKey: envKeys.awsSecretAccessKey
  }
});
import { updateUser } from '../services/userService'

const NewSplitForm = (props) => {
  const [split, setSplit] = useState({});
  const [title, setTitle] = useState('')
  const [isTitleValid, setIsTitleValid] = useState(false)
  const [genre, setGenre] = useState('')
  const [isGenreValid, setIsGenreValid] = useState(false)
  const [formData, setFormData] = useState({})
  const [canSubmitForm, setCanSubmitForm] = useState(false)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [isSubmittingSignature, setIsSubmittingSignature] = useState(false)
  const [user, setUser] = useState({})
  const [canSaveSignature, setCanSaveSignature] = useState(false)
  const [authorId, setAuthorId] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [form] = Form.useForm()
  const [signatureUrl, setSignatureUrl] = useState('')
  const [formDisabled, setFormDisabled] = useState(false)
  const { onSubmit, showSplitModal, selectedSplit } = props
  let disableEntireForm = false

  const sigCanvas = useRef(null)
  useEffect(() => {
    const loggedInUser = getFromStorage('user')
    const isExistingSplit = !!selectedSplit
    setUser(loggedInUser)
    if (loggedInUser && loggedInUser.signatureUrlPath) {
      let signaturePath = formatImageUrl(loggedInUser.signatureUrlPath)
      setSignatureUrl(signaturePath)
      form.setFieldsValue({signature: signaturePath})
      updateSubitForm()
    }
    if(isExistingSplit) setSplit(selectedSplit)
    setAuthorId(loggedInUser.id)

    if (isExistingSplit && loggedInUser && loggedInUser.id !== selectedSplit.authorId) {
      setFormDisabled(true)
    }

    if(selectedSplit && selectedSplit.composers) {
      setCanSubmitForm(true)
      return form.setFieldsValue({
        title: selectedSplit.title,
        genre: selectedSplit.genre,
        composers: Object.values(selectedSplit.composers),
        signature: formatImageUrl(loggedInUser.signatureUrlPath)
      })

    }
    form.setFieldsValue({
      composers: [ 
        {
          firstName: loggedInUser.firstName,
          lastName: loggedInUser.lastName,
          emailAddress: loggedInUser.emailAddress,
          isAuthor: true,
          isActive: true
        }
      ]
    })
  }, [user.firstName, selectedSplit && selectedSplit.id])

  function formatImageUrl(url) {
    return `${cloudFrontUrls.signatures}${url}`
  }

  function updateSubitForm() {
    if (formData.title &&
        formData.genre &&
        formData.composers && 
        formData.composers.length > 0 &&
        formData.composers[0] &&
        formData.composers[0].firstName &&
        formData.composers[0].lastName &&
        formData.composers[0].emailAddress &&
        formData.composers[0].percent &&
        formData.composers[0].idNumber &&
        formData.signature
      ){
        setCanSubmitForm(true)
      } else {
        setCanSubmitForm(false)
      }
  }

  async function uploadSignature() {
    let base64Data = Buffer.from(sigCanvas.current.toDataURL().replace(/^data:image\/\w+;base64,/, ""),'base64')
    const albumKey = encodeURIComponent(user.uuid) + '/' + `${Date.now()}.jpeg`;
    const albumBucketName = envKeys.awsSignaturesBucketName;

    const s3_params = {
      Bucket: albumBucketName, 
      Key: albumKey,
      Body: base64Data,
      ContentEncoding: 'base64',
      ContentType: 'image/jpeg'
    };

    setIsSubmittingSignature(true)
    const response = await client.send(new PutObjectCommand(s3_params));

    if (response && response['$metadata'] && response['$metadata'].httpStatusCode === 200 ) {
      const user_params = {
        ...user,
        userId: user.id,
        signatureUrlPath: albumKey
      }

      const response = await updateUser(user_params)
      if (response && response.ok){
        const signaturePath = formatImageUrl(response.data.signatureUrlPath)
        setSignatureUrl(signaturePath)
        form.setFieldsValue({signature: signaturePath})
        updateSubitForm()

        if (selectedSplit) {
          setInStorage({
            key: 'user',
            value: response.data
          })
          window.location.reload()
        }
      } else {
        // There was an error with uploading the song, show popup
      }
    } else {
      // there was an error with the S3 upload
    }
    setIsSubmittingSignature(false)
  }

  async function handleFormSubmittion(data) {
    setIsFormLoading(true)
    let response
    if (selectedSplit) {
      response = await updateSplit({...data, authorId, ...{id: selectedSplit.id}})
    }
    else {
      response = await createSplit({...data, authorId})
    }
    setIsFormLoading(false)
    onSubmit()

    if (response && response.ok){
      confirmAlert({
        title: 'Success',
        message: `Your splitsheet has been ${ selectedSplit ? 'updated' : 'created'}. We have sent an email to the other composers to notify them of this`,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              window.location.reload()
            }
          }
        ]
      })
    }
    else {
      confirmAlert({
        title: 'Error',
        message: response.error,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {}
          }
        ]
      })
    }
  }

  // if (redirect) {
	// 	return <Redirect push to="/splits/new" />
	// }

  return (
    <div>
      <center>
        <Title level={4}>New Splitsheet</Title>
        <br/>
      </center>

      <div className="row align-items-center">
        <div className="col-lg-12 col-md-12">
          < br/>
          <div className="row">
            <div className="col-md-12">
              <Form
                form={form}
                disabled={formDisabled}
                onValuesChange={(changedVal, allVals) => {
                  setFormData({...formData, ...allVals})
                  updateSubitForm()
                }}
                name="dynamic_form_nest_item"
                onFinish={(values) => {
                  handleFormSubmittion(values)
                }}
                onFinishFailed={(values, errorFields, outOfDate) => {
                  onSubmit()
                  confirmAlert({
                    title: 'Error',
                    message: 'Please fix the errors on the form',
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => {
                          setTimeout(() => {
                            showSplitModal()
                          }, 1000)
                        }
                      }
                    ]
                  })
                }}
                autoComplete="off">
                <div className="row">
                  <p>Song Details:</p>
                  <div className="col-md-6">
                    <Form.Item
                      name="title"
                      rules={[
                        { required: true, message: 'Missing song title' },
                        { whitespace: true, message: 'Remove white spaces' },
                        // { pattern: /^(?! )[A-Za-z0-9 ]*(?<! )$/, message: 'Invalid Song Title' }
                      ]}
                    >
                      <Input size="large" placeholder="Song Title" className="form-control" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="genre"
                      rules={[{ required: true, message: 'Missing song genre' }]}
                    >
                      <Select size={'large'} bordered={false} placeholder="Genre" className="form-control" >
                      <Option value="afro-beat">Afro-Beat</Option>
                        <Option value="afrobeats">Afrobeats</Option>
                        <Option value="afro-folk">Afro Folk</Option>
                        <Option value="afro-fusion">Afro Fusion</Option>
                        <Option value="afro-house">Afro House</Option>
                        <Option value="afro-pop">Afro Pop</Option>
                        <Option value="afro-soul">Afro Soul</Option>
                        <Option value="african-dancehall">African Dancehall</Option>
                        <Option value="african-reggae">African Reggae</Option>
                        <Option value="afrikaans">Afrikaans</Option>
                        <Option value="alte">Alte</Option>
                        <Option value="amapiano">Amapiano</Option>
                        <Option value="benga">Benga</Option>
                        <Option value="bongo-flava">Bongo Flava</Option>
                        <Option value="classical">Classical</Option>
                        <Option value="coup-de-cale">Coup De Cale</Option>
                        <Option value="country">Country</Option>
                        <Option value="dancehall">Dancehall</Option>
                        <Option value="electronic-dance">Electronic Dance</Option>
                        <Option value="folk">Folk</Option>
                        <Option value="gosepl">Gospel</Option>
                        <Option value="gqom">Gqom</Option>
                        <Option value="highlife">Highlife</Option>
                        <Option value="hip-hop">Hip-hop</Option>
                        <Option value="hip-life">HipLife</Option>
                        <Option value="house">House</Option>
                        <Option value="jazz">Jazz</Option>
                        <Option value="kizomba">Kizomba</Option>
                        <Option value="kuduro">Kuduro</Option>
                        <Option value="kwaito">Kwaito</Option>
                        <Option value="makossa">Makossa</Option>
                        <Option value="maskandi">Maskandi</Option>
                        <Option value="mbalax">Mbalax</Option>
                        <Option value="mbaqanga">Mbaqanga</Option>
                        <Option value="ndombolo">Ndombolo</Option>
                        <Option value="other">Other</Option>
                        <Option value="pop">Pop</Option>
                        <Option value="reggae">Reggae</Option>
                        <Option value="rnb">RnB</Option>
                        <Option value="rock">Rock</Option>
                        <Option value="shangaan-electro">Shangaan Electro</Option>
                        <Option value="soul">Soul</Option>
                        <Option value="soukous">Soukous</Option>
                        <Option value="taarab">Taarab</Option>
                        <Option value="zouglou">Zouglou</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <br />
                <p>Collaborators:</p>
                <Form.List name="composers" className='form-list-splits'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space direction={screen.width < 800 ? 'vertical' : 'horizontal'} key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <div className="row">
                            <div className="col-md-12">
                              <Form.Item
                                {...restField}
                                name={[name, 'role']}
                                fieldKey={[fieldKey, 'role']}
                                rules={[
                                  { required: true, message: 'role' },
                                  // { pattern: /^(?! )[a-zA-Z ,.'-]*(?<! )$/, message: 'Invalid role' },
                                  { whitespace: true, message: 'Remove white spaces' }
                                ]}
                              >
                                <Select size={'large'} bordered={false} placeholder="role" className="" style={{ width: 200 }} >
                                  <Option value="composer">Composer (C)</Option>
                                  <Option value="author">Author (A)</Option>
                                  <Option value="composer-author">Composer & Author (CA)</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Item
                                {...restField}
                                name={[name, 'firstName']}
                                fieldKey={[fieldKey, 'firstName']}
                                rules={[
                                  { required: true, message: 'Missing first name' },
                                  // { pattern: /^(?! )[a-zA-Z ,.'-]*(?<! )$/, message: 'Invalid First name' },
                                  { whitespace: true, message: 'Remove white spaces' }
                                ]}
                              >
                                <Input placeholder="First Name" className="form-control" />
                              </Form.Item>
                            </div>
                            <div className="col-md-6">
                              <Form.Item
                                {...restField}
                                name={[name, 'lastName']}
                                fieldKey={[fieldKey, 'lastName']}
                                rules={[
                                  { required: true, message: 'Missing last name' },
                                  // { pattern: /^(?! )[a-zA-Z ,.'-]*(?<! )$/, message: 'Invalid last name' },
                                  { whitespace: true, message: 'Remove white spaces' }
                                ]}
                              >
                                <Input placeholder="Last Name" className="form-control" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Item
                                {...restField}
                                name={[name, 'emailAddress']}
                                fieldKey={[fieldKey, 'emailAddress']}
                                rules={[
                                  {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                  },
                                  {
                                    required: true,
                                    message: 'Missing email',
                                  },
                                  {
                                    whitespace: true, message: 'Remove white spaces'
                                  }
                                ]}
                              >
                                <Input placeholder="Email" className="form-control" />
                              </Form.Item>
                            </div>
                            <div className="col-md-6">
                              <Form.Item
                                {...restField}
                                name={[name, 'percent']}
                                fieldKey={[fieldKey, 'percent']}
                                rules={[
                                  {
                                    type: 'number',
                                    message: 'The input is not a valid number!',
                                  },
                                  {
                                    required: true,
                                    message: 'Missing percent',
                                  },
                                ]}
                              >
                                <InputNumber
                                  defaultValue={0}
                                  min={0}
                                  max={100}
                                  formatter={value => `${value}%`}
                                  parser={value => value.replace('%', '')}
                                  style={{height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Form.Item
                                {...restField}
                                name={[name, 'idNumber']}
                                fieldKey={[fieldKey, 'idNumber']}
                                rules={[
                                  { required: true, message: 'Missing idNumner' },
                                  // { pattern: /^(?! )[a-zA-Z0-9 ,.'-]*(?<! )$/, message: 'Invalid ID/Passport number' },
                                  { whitespace: true, message: 'Remove white spaces' }
                                ]}
                              >
                                <Input placeholder="ID or Passport Number" className="form-control" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Form.Item
                                {...restField}
                                name={[name, 'publisher']}
                                fieldKey={[fieldKey, 'publisher']}
                                rules={[
                                  { required: false, message: '' },
                                  // { pattern: /^(?! )[a-zA-Z ,.'-]*(?<! )$/, message: 'Invalid last name' },
                                  { whitespace: true, message: 'Remove white spaces' }
                                ]}
                              >
                                <Input placeholder="Publisher (Optional)" className="form-control" />
                              </Form.Item>
                            </div>
                          </div>
                          <MinusCircleOutlined onClick={() => {
                            remove(name)
                            const listOfComposers = form.getFieldValue('composers')
                            if (listOfComposers && (listOfComposers.length == 0 || !listOfComposers[0].isAuthor )) {
                              form.setFieldsValue({
                                composers: [ 
                                  {
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    emailAddress: user.emailAddress,
                                    isAuthor: true,
                                    isActive: true
                                  },
                                  {}
                                ]
                              })
                            }
                          }} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" className="btn btn-primary" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add Collaborator
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Item
                        name="signature"
                        rules={[
                          { required: true, message: 'Missing Signature' }
                        ]}
                      >
                        <Input type="hidden" size="large" placeholder="Song Title" className="form-control" />
                      </Form.Item>
                      <p>Please sign below:</p>
                      {
                        signatureUrl ?
                        <img style={{ height: '100px', width: 'auto' }} src={signatureUrl} />
                        :
                        <SignatureCanvas
                          ref={sigCanvas}
                          penColor='black'
                          backgroundColor='rgb(255,255,255)'
                          canvasProps={{width: 'auto', height: 150, className: 'sigCanvas'}}
                          onEnd={() => {
                            setCanSaveSignature(true)
                          }}
                        />
                      }
                      <div style={{ marginTop: 30 }} className='signature-btn-wrapper'>
                        <Button
                          disabled={ !signatureUrl && !canSaveSignature}
                          type="primary"
                          shape="round"
                          size={'small'}
                          className="btn btn-primary atn-button btn-with-icon"
                          loading={isSubmittingSignature}
                          onClick={() => {
                            signatureUrl ? setSignatureUrl(undefined) : uploadSignature()
                          }}
                        >
                          {!signatureUrl ? 'Save Signature' : 'Edit Signature'}
                        </Button>
                        { !signatureUrl && canSaveSignature && !isSubmittingSignature && 
                          <Button
                            disabled={!canSaveSignature}
                            type="primary"
                            shape="round"
                            size={'small'}
                            className="btn btn-primary atn-button btn-with-icon"
                            onClick={() => {
                              sigCanvas.current.clear()
                            }}
                          >
                            Clear Signature
                          </Button>
                        }
                      </div>
                    </div>
                  </div>
                <Form.Item>
                <br/>
                <br/>
                <div className='action-button-wrapper'>
                  { user && user.id && user.id === split.authorId && selectedSplit ?
                    <Button
                      disabled={!canSubmitForm}
                      type="primary"
                      shape="round"
                      size={'large'}
                      htmlType="submit"
                      className="btn btn-primary atn-button btn-with-icon"
                      loading={isFormLoading}
                    >
                      {'Update'}
                    </Button>
                  :
                    <Button
                      disabled={!canSubmitForm}
                      type="primary"
                      shape="round"
                      size={'large'}
                      htmlType="submit"
                      className="btn btn-primary atn-button btn-with-icon"
                      loading={isFormLoading}
                    >
                      {'Submit Form'}
                    </Button>
                  }
                </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewSplitForm