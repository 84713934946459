import React, { useEffect, useState } from 'react'
// Components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { getFromStorage } from '../helpers/appHelpers'
import { Redirect } from 'react-router-dom';
import Success from '../components/Success'

const SuccessPage = (props) => {
	const [redirect, setRedirect] = useState(false);
	const [user, setUser] = useState({});

	useEffect(() => {
		const loggedInUser = getFromStorage('user')
		if (loggedInUser) {
			setUser(loggedInUser)
		}
		else {
			setRedirect(true)
		}
	}, [])

	if (redirect) {
		return <Redirect push to="/dashboard" />
	}

	return (
		<div>
			<Navbar
				isDefaultSticky={true}
				isLoggedIn={true}
			/>
			<Success />
			<br />
			<br />
			<Footer />
		</div>
	)
}

export default SuccessPage