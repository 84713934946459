import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import BrandingPoster from "images/thumbnail-cmo.jpeg"
import LegalPoster from "images/thumbnail-legal.jpeg"
import SheetPoster from "images/thumbnail-splitsheet.jpeg"
import TeamPoster from "images/thumbnail-team.jpeg"
import $ from 'jquery';
class Videos extends Component {

	componentDidMount() {
		document.querySelectorAll(".inlineVideo").forEach((el) => {
			el.onplay = function(e){
				// pause all the videos except the current.
				document.querySelectorAll(".inlineVideo").forEach((el1) => {
						if(el === el1)
								el1.play();
						else
								el1.pause();
				});
			}
		});
	}

	render() {
		return (
			<section id="features" className="features-area ptb-100 bg-f9f9f9">
				<div className="container">
					<div className="section-title">
						<h1>VIDEOS</h1>
						<br/>
						<p>Gain access to the "MOTI Digital Split Sheet" and make music copyright 
							disputes a thing of the past! With the "MOTI Digital Split Sheet" you 
							can immediately input percentage splits so composition royalties flow 
							to the correct rightsholders.
						</p>
						<br/>
					</div>

					<Carousel
						autoPlay={true}
						infiniteLoop={true}
						interval={5000}
						showStatus={false}
						showThumbs={true}
						centerMode={true}
						centerSlidePercentage={50}
					>
						<video className="inlineVideo" controls width="500px" height="auto"  poster={BrandingPoster}>
							<source src="https://d1jxpqxy0qzag1.cloudfront.net/51c0613d-4a20-4c1d-8cbd-e74ac9b018cd/mp4/MOTI BRAND PROMO_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4" type="video/mp4" />
						</video>

						<video className="inlineVideo" controls width="500px" height="auto"  poster={TeamPoster}>
							<source src="https://d1jxpqxy0qzag1.cloudfront.net/2ea91b13-8b7c-4673-bdb7-b5a7ce10a27e/mp4/MOTI-TEAMS (FIXED)_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4" type="video/mp4" />
						</video>

						{/* <video controls width="500px" height="auto"  poster={BrandingPoster}>
							<source src="https://d1jxpqxy0qzag1.cloudfront.net/9f5f2a71-fd45-45ec-aa3c-c89933b4e027/mp4/MOTI C.M.O'S PROMO_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4" type="video/mp4" />
						</video> */}

						<video className="inlineVideo" controls width="500px" height="auto"  poster={SheetPoster}>
							<source src="https://d1jxpqxy0qzag1.cloudfront.net/08d4dbea-2eb4-4866-9965-174da14c16ca/mp4/M.OT.I - SO YOU MADE A SONG_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4" type="video/mp4" />
						</video>

						<video className="inlineVideo" controls width="500px" height="auto"  poster={LegalPoster}>
							<source src="https://d1jxpqxy0qzag1.cloudfront.net/2c454eed-4713-44fb-94fb-29ddbb54f894/mp4/MOTI LEGAL PROMO_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4" type="video/mp4" />
						</video>
					</Carousel>
				</div>
			</section>
		);
	}
}

export default Videos;
