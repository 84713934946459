import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import CapassoLogo from 'images/capasso-logo.png'
import SamroLogo from 'images/samro-logo.png'
import SampraLogo from 'images/sampra-logo.png'
import RisaLogo from 'images/risa-logo.png'

const options = {
	loop: true,
	nav: false,
	dots: false,
	autoplayHoverPause: true,
	autoplay: true,
	margin: 30,
	navText: [
		"<i class='fas fa-angle-left'></i>",
		"<i class='fas fa-angle-right'></i>"
	],
	responsive: {
		0: {
			items: 2,
		},
		768: {
			items: 3,
		},
		1024: {
			items: 4,
		},
		1200: {
			items: 6,
		}
	}
}

class Partner extends Component {

	state = { display: false };

	componentDidMount() {
		this.setState({ display: true })
	}

	render() {
		return (
			<section className="partner-area">
				<div className="container">
					<h3>Trusted by many music professionals</h3>

					<div className="row">
						{this.state.display ? <OwlCarousel
							className="partner-slides owl-carousel owl-theme"
							{...options}
						>
							<div className="col-lg-12 col-md-12">
								<div className="partner-item">
									<a href="#" target="_blank">
										<img src={CapassoLogo} alt="logo" />
									</a>
								</div>
							</div>

							<div className="col-lg-12 col-md-12">
								<div className="partner-item">
									<a href="#" target="_blank">
										<img src={SamroLogo} alt="logo" />
									</a>
								</div>
							</div>

							<div className="col-lg-12 col-md-12">
								<div className="partner-item">
									<a href="#" target="_blank">
										<img src={SampraLogo} alt="logo" />
									</a>
								</div>
							</div>

							<div className="col-lg-12 col-md-12">
								<div className="partner-item">
									<a href="#" target="_blank">
										<img src={RisaLogo} alt="logo" />
									</a>
								</div>
							</div>

							{/* <div className="col-lg-12 col-md-12">
								<div className="partner-item">
									<a href="#" target="_blank">
										<img src={AgencyPartner5Image} alt="logo" />
									</a>
								</div>
							</div>

							<div className="col-lg-12 col-md-12">
								<div className="partner-item">
									<a href="#" target="_blank">
										<img src={AgencyPartner6Image} alt="logo" />
									</a>
								</div>
							</div> */}
						</OwlCarousel> : ''}
					</div>
				</div>
			</section>
		);
	}
}

export default Partner;
