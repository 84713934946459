import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import Client1Image from "images/client1.png";
import Client2Image from "images/client2.png";
import Client3Image from "images/client3.png";

const options = {
	loop: true,
	nav: false,
	dots: false,
	autoplayHoverPause: true,
	autoplay: true,
	margin: 30,
	navText: [
		"<i class='fas fa-angle-left'></i>",
		"<i class='fas fa-angle-right'></i>"
	],
	responsive: {
		0: {
			items: 1,
		},
		768: {
			items: 2,
		},
		1200: {
			items: 3,
		}
	}
}

class Team extends Component {

	state = { display: false };

	componentDidMount() {
		this.setState({ display: true })
	}

	render() {
		return (
			<section id="testimonials" className="feedback-area ptb-100">
				<div className="container">
					<div className="section-title">
						<h1>THE TEAM</h1>
						<p>Meet the team behind #MOTI</p>
					</div>

					<div className="row">
						{this.state.display ? <OwlCarousel
							className="feedback-slides owl-carousel owl-theme"
							{...options}
						>
							<div className="col-lg-12 col-md-12">
								<div className="feedback-item">
									<img src={Client1Image} alt="client" />
									<div className="client-info">
										<h3>Munya Chanetsa</h3>
										<span>FOUNDER</span>
									</div>
									<p>Munya is a prominent music executive in South Africa and throughout
										the continent. With his A&R and licensing experience spanning over
										ten years he has engaged mobile and online platforms, independent
										record labels, artists and music publishers. He is a familiar 
										feature presenter on music copyright on leading forums both locally and abroad
									</p>
									<p>
										Currently Munya serves as A&R manager (Africa) at Sony/ATV Music Publishing South Africa
									</p>

									<i className="fas fa-quote-left"></i>
								</div>
							</div>

							<div className="col-lg-12 col-md-12">
								<div className="feedback-item">
									<img src={Client2Image} alt="client" />
									<div className="client-info">
										<h3>Banessa</h3>
										<span>Strategy and Operations</span>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
									<i className="fas fa-quote-left"></i>
								</div>
							</div>

							<div className="col-lg-12 col-md-12">
								<div className="feedback-item">
									<img src={Client3Image} alt="client" />
									<div className="client-info">
										<h3>Fresh Projuice</h3>
										<span>Branding and Digital</span>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
									<i className="fas fa-quote-left"></i>
								</div>
							</div>
						</OwlCarousel> : ''}
					</div>
				</div>
			</section>
		);
	}
}

export default Team;
