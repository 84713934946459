import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import NewSplitForm from '../components/NewSplitForm';
import { getFromStorage } from '../helpers/appHelpers'
import { Redirect } from 'react-router-dom';
import HeaderCard from '../components/HeaderCard';
import Splits from '../components/Splits';
import { LoadingOutlined } from '@ant-design/icons';
import { getAllSplits } from '../services/splitService'
import { Modal } from 'antd';

const newSplitPage = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [user, setUser] = useState({});
  const [splitsLoading, setSplitsLoading] = useState(false);
  const [splits, setSplits] = useState([]);
  const [splitModalVisible, setSplitModalVisible] = useState(false);

  useEffect(() => {
		const loggedInUser = getFromStorage('user')
		if (loggedInUser){
      setUser(loggedInUser)
      fetchSplits()
    }
    else {
      setRedirect(true)
    }
	}, [user.firstName, JSON.stringify(splits)])

  async function fetchSplits() {
    if (user && Object.keys(user).length > 0){
      setSplitsLoading(true)
      const response = await getAllSplits({
        userUuid: user.uuid
      })
      setSplitsLoading(false)

      if (response && response.ok){
        setSplits(response.data)
      }
      else {
        // Do nothing
      }
    }
  }

  if (redirect) {
		return <Redirect push to="/" />
	}

  return (
    <div>
      <Navbar 
        isDefaultSticky={true}
        isLoggedIn={true}
      />
      <Modal
        centered
        open={splitModalVisible}
        onOk={() => setSplitModalVisible(false)}
        onCancel={() => setSplitModalVisible(false)}
        width={1400}
        footer={null}
      >
        <NewSplitForm onSubmit={() => {setSplitModalVisible(false)}} showSplitModal={() => {setSplitModalVisible(true)}} />
      </Modal>
      <section className="new-split-area">
        <HeaderCard
          heading='My Split Sheets'
          body={["A split sheet is a written agreement that identifies each contributor to a song and establishes ownership percentages amongst them. The agreed percentages determine how much each contributor will receive from the royalties generated by their music.", "Split sheets are necessary for every song you write with collaborators. They ensure everyone involved in the creative process is properly credited and paid for the work they contributed.", "Split sheets are an important part of documenting which collaborator worked on what song", "Use our digital split sheet ledger to keep track of all collaborators on your songs for free!", "Collaborators who are added to a split will be notified by email for free"]}
          imageUrl='https://d3v0zkcslccxqy.cloudfront.net/logo.png'
          onActionPress={() => {setSplitModalVisible(true)}}
          buttonTitle='New Split Sheet'
          buttonIcon='newSplitSheet'
        />
        <br/>
        <br/>
        { splitsLoading ?
          <div>
            <h2 className="text-dark font-weight-bold mb-2"> My Splits</h2>
            <br/>
            <LoadingOutlined style={{ fontSize: 24 }} spin />
            <br/>
          </div>
          :
          <div>
            < Splits splits={splits} user={user} />
            <br/>
            <br/>
          </div>
        }
      </section>
      <Footer />
    </div>
  )
}

export default newSplitPage