import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import MetaDataForm from '../components/MetaDataForm';
import { getFromStorage } from '../helpers/appHelpers'
import { Redirect } from 'react-router-dom';
import HeaderCard from '../components/HeaderCard';

const metaDataPage = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
		const loggedInUser = getFromStorage('user')
		if (loggedInUser){
      setUser(loggedInUser);
    }
    else {
      setRedirect(true)
    }
	}, [])

  if (redirect) {
		return <Redirect push to="/" />
	}

  return (
    <div>
      <Navbar 
        isDefaultSticky={true}
        isLoggedIn={true}
      />
      <section className="metadata-area">
        <HeaderCard
          heading='🤓 My Meta Data'
          body={["My metadata is the information included in audio files used to identify, label and present audio content.", "Metadata is used by every major device and music streaming application including smartphones, Spotify, Apple Music, Soundcloud, YouTube and all other major online music services.", "Metadata includes information like artist, genre, label, song titles, album name and track numbers. This is very important as it keeps your music documented and up-to-date.", "Meta data also allows your fans to know who composed the song, and what album/project the song comes from", "Use the drag-and-drop tool below to view the meta data of your mp3 files", "(Paying customers can edit and download the up-to-date versions of their music)"]}
          imageUrl='https://d3v0zkcslccxqy.cloudfront.net/logo.png'          
        />
        <br/>
        <br/>
        <br/>
        <MetaDataForm />
      </section>
      <Footer />
    </div>
  )
}

export default metaDataPage