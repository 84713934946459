import React, { useEffect, useState, useRef } from 'react';
import SubscribeModal from './SubscribeModal';
import { getFromStorage } from '../helpers/appHelpers'
import { updateVideoProgress } from '../services/videoProgressService';
import ReactPlayer from 'react-player'
import { Tag, Divider, Modal, Button, Input } from 'antd'
import TimeAgo from 'javascript-time-ago'
import { capitalizeFirstLetter } from '../helpers/appHelpers';
import { LockOutlined, PlayCircleOutlined } from '@ant-design/icons';
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import ReactTimeAgo from 'react-time-ago'
import { Alert } from 'antd';
import { isPayingCustomer } from '../helpers/appHelpers'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

const VideoSummary = (props) => {
  const [user, setUser] = useState({});
  const [visible, setVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [recentVideoDuration, setRecentVideoDuration] = useState(0);
  const { videos } = props
  const { videoProgress, onSubscribePress } = props
  let player = useRef()
  const seekPlayer = (percentage) => player.current.seekTo(percentage);
  const isSubscriptionActive = isPayingCustomer(user)

  useEffect(() => {
    const loggedInUser = getFromStorage('user')
    setUser(loggedInUser)
  }, [user.firstName, JSON.stringify(videos)])

  const updateUserProgress = async (params) => {
    const response = await updateVideoProgress(params)
    if (response && response.ok) {

    }
  }

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="d-sm-flex mt-4 mb-4 justify-content-between align-items-start">
          <h2 className="text-dark font-weight-bold mb-2"> Watch Now</h2>
          < br />
        </div>
        {/* <Input size="large" placeholder="Search" prefix={<SearchOutlined />} />
        < br/>
        < br/> */}
        < br />
        <div className="row">
          {
            videos.map((video, index) => {
              return (
                <div key={index} className="col-md-6 col-xl-4">
                  <div className="card card-social card-split">
                    <div className="card-block border-bottom">
                      <div className="row align-items-center justify-content-center">
                        <div className="col text-left">
                          <div>
                            {
                              video.requiresPayment && !isSubscriptionActive && <div class="ribbon-gray ribbon-top-right"><span>🔒 Locked</span></div>
                            }
                            {
                              video.type === 'promotional' && <div class="ribbon-blue ribbon-top-right"><span>Preview</span></div>
                            }
                            {
                              video.type === 'masterclass' && !video.requiresPayment && <div class="ribbon-green ribbon-top-right"><span>⭐ Free</span></div>
                            }
                          </div>
                          <h3>{capitalizeFirstLetter(video.title)}</h3>
                          <h5 className="text-c-green mb-0"><span className="text-muted">{capitalizeFirstLetter(video.description)}</span></h5>
                          <p style={{ marginTop: 10, marginBottom: 10, fontSize: 10 }}>{secondsToHms(video.duration)}</p>
                          <div>
                            {
                              video.tags &&
                              video.tags.map((tag, index) => {
                                return (
                                  <Tag key={index} color="geekblue">{tag}</Tag>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{
                      background: `${video.requiresPayment && !isSubscriptionActive ? 'rgb(211,211,211)' : 'rgb(255,255,255)'}`,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }} className="card-block">
                      <div className="row align-items-center justify-content-center card-active">

                      </div>
                      <center>
                        <div className="col-12 mt-4">
                          <Button onClick={() => {
                            if (video.requiresPayment && !isSubscriptionActive) {
                              onSubscribePress()
                            } else {
                              setSelectedVideo(video)
                              setVisible(true)
                            }
                          }}
                            className={`btn btn-primary atn-button btn-with-icon ${video.requiresPayment && !isSubscriptionActive ? 'btn-primary-locked' : ''
                              }`}
                            style={
                              {
                                ...(video.requiresPayment && !isSubscriptionActive ?
                                  { backgroundColor: '#A9A9A9', borderColor: '#A9A9A9' } :
                                  { backgroundColor: '#000', borderColor: '#000' }
                                )
                              }}
                            type="primary" shape="round" size={'large'}
                          >
                            {video.requiresPayment && !isSubscriptionActive ? "🔒 " : <PlayCircleOutlined />}
                            {video.requiresPayment && !isSubscriptionActive ? 'Subscribe' : 'Watch'}
                          </Button>
                          <br />
                        </div>
                      </center>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        {selectedVideo &&
          <Modal
            bodyStyle={{ padding: 0 }}
            centered
            visible={visible}
            footer={false}
            onOk={() => {
              setVisible(false);
            }}
            onCancel={() => {
              setVisible(false)
            }}
            destroyOnClose={true}
            afterClose={() => {
              updateUserProgress({
                userId: user.id,
                videoId: selectedVideo.id,
                progress: recentVideoDuration
              })
              setSelectedVideo({});
              setRecentVideoDuration(0)
            }}
            width={1000}
          >
            <div className="">
              <div style={{ padding: 24 }}>
                <h3 className="text-dark font-weight-bold mb-2">{selectedVideo.title}</h3>
              </div>
              <center>
                <ReactPlayer
                  ref={player}
                  width='100%'
                  height='100%'
                  onStart={() => {
                    seekPlayer(parseFloat(videoProgress[selectedVideo.id].progress))
                  }}
                  url={selectedVideo.videoUrl}
                  controls={true}
                  progressInterval={1000}
                  stopOnUnmount={true}
                  onProgress={(properties) => {
                    if (properties && properties.played) {
                      // update user's progress in state
                      setRecentVideoDuration(properties.played)
                    }
                  }}
                  onEnded={() => {
                    // set the progress as complete
                  }}
                />
              </center>
              <div style={{ padding: 24 }}>
                <p className="text-dark font-weight-bold mb-2">{selectedVideo.description}</p>
                <p className="text-dark mb-2">{selectedVideo.subDescription}</p>
                <div>
                  <p style={{ fontSize: 11 }}>
                    {
                      selectedVideo.createdAt &&
                      <ReactTimeAgo date={selectedVideo.createdAt} locale="en-US" />
                    }
                  </p>
                </div>
                < br />
                <div>
                  {
                    selectedVideo.tags &&
                    selectedVideo.tags.map((tag) => {
                      return (
                        <Tag color="#ff335c">{tag}</Tag>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Modal>
        }
      </div>
    </div>
  )
}

export default VideoSummary