import React, { useEffect, useState, useMemo } from 'react';
import { Upload, Tooltip, Select, Divider, DatePicker } from 'antd';
const { Option } = Select;
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { Modal, Input, Button } from 'antd';
import 'react-credit-cards/es/styles-compiled.css';
import { Table, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { uploadSong, getAllSongs, updateSong, deleteSong } from '../services/songService';
import { getFromStorage } from '../helpers/appHelpers';
const musicMetadata = require('music-metadata-browser');
import { CSVLink } from "react-csv";
import { cloudFrontUrls, envKeys } from '../utilities/config';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { confirmAlert } from 'react-confirm-alert';
import EllipsisText from "react-ellipsis-text";
import moment from 'moment';
import countryList from 'react-select-country-list';

const { Dragger } = Upload;
const client = new S3Client({ 
  region: envKeys.awsRegion, 
  credentials: { 
    accessKeyId: envKeys.awsAccessKeyId,
    secretAccessKey: envKeys.awsSecretAccessKey
  }
});

const MetaDataForm = (props) => {
  const windowWidth = window.innerWidth;
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isBillingModalVisible, setIsBillingModalVisible] = useState(false)
  const [selectedSong, setSelectedSong] = useState({})
  const [songs, setSongs] = useState([]);
  const [values, setValues] = useState({});
  const [focus, setFocus] = useState('number');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [user, setUser] = useState({});
  const [metadataLoading, setMetadataLoading] = useState(false);
  const [metadataSubmitting, setMetadataSubmitting] = useState(false);
  const [deletingLoading, setDeletingLoading] = useState(false);

  const [selectedAlbumTitle ,setSelectedAlbumTitle] = useState('');
  const [selectedAlbumDescription ,setSelectedAlbumDescription] = useState('');
  const [selectedAlbumArtist, setSelectedAlbumArtist] = useState('');
  const [selectedTrackNumber ,setSelectedTrackNumber] = useState('');
  const [selectedTrackName, setSelectedTrackName] = useState('');
  const [selectedAlternateTrackName, setSelectedAlternateTrackName] = useState('');
  const [selectedFeaturedArtists, setSelectedFeaturedArtists] = useState('');
  const [selectedSongGenre, setSelectedSongGenre] = useState([]);
  const [selectedSongSubGenre, setSelectedSongSubGenre] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const [selectedIsrc, setSelectedIsrc] = useState('');
  const [selectedSongwriterAuthor, setSelectedSongwriterAuthor] = useState('');
  const [selectedBeatmakerComposer, setSelectedBeatmakerComposer] = useState('');
  const [selectedProducer, setSelectedProducer] = useState('');
  const [selectedExecutiveProducer, setSelectedExecutiveProducer] = useState('');
  const [selectedEngineer, setSelectedEngineer] = useState('');
  const [selectedMastering, setSelectedMastering] = useState('');
  const [selectedMixing, setSelectedMixing] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedCountryOfOrigin, setSelectedCountryOfOrigin] = useState('');
  const [selectedReleaseDate, setSelectedReleaseDate] = useState('');
  const [selectedDuration, setSelectedDuration] = useState('');
  const [selectedMood, setSelectedMood] = useState('');
  const [selectedSongPath, setSelectedSongPath] = useState('');
  const [selectedPublisher, setSelectedPublisher] = useState('');

  const countries = useMemo(() => countryList().getData(), [])
  const genres = [
    "Afro-Beat",
    "Afrobeats",
    "Afro Folk",
    "Afro Fusion",
    "Afro House",
    "Afro Pop",
    "Afro Soul",
    "African Dancehall",
    "African Reggae",
    "Afrikaans",
    "Alte",
    "Amapiano",
    "Benga",
    "Bongo Flava",
    "Classical",
    "Coup De Cale",
    "Country",
    "Dancehall",
    "Electronic Dance",
    "Folk",
    "Gospel",
    "Gqom",
    "Highlife",
    "Hip-hop",
    "HipLife",
    "House",
    "Jazz",
    "Kizomba",
    "Kuduro",
    "Kwaito",
    "Makossa",
    "Maskandi",
    "Mbalax",
    "Mbaqanga",
    "Ndombolo",
    "Other",
    "Pop",
    "Reggae",
    "RnB",
    "Rock",
    "Shangaan Electro",
    "Soul",
    "Soukous",
    "Taarab",
    "Zouglou"
  ]

  const GenreOptions =[]
  genres.map((genre, index) => {
    GenreOptions.push(
      <Option key={index} value={genre}>{genre}</Option>
    )
  })

  const loadExistingSongs = async () => {
    const response = await getAllSongs({
      userId: user.id
    })

    if (response && response.ok) {
      const songs = response.data
      songs.forEach((song) => {
        const songPath = `${cloudFrontUrls.songs}${song.urlPath}`
        renderSongs(songPath)
      })
    } else {
      // failed to pull songs
    }
  }
  useEffect(() => {
    const loggedInUser = getFromStorage('user')
    setUser(loggedInUser)
    loadExistingSongs()
  }, [user.firstName])

  const customColRender = (text, record, index) => {
    const hasAValue = !!text
    if (typeof text === 'number') text = text.toString()
    if (text instanceof Array) text = text.join(', ')
    return (
      <Tooltip title={hasAValue ? text : 'No metadata found'} color={hasAValue ? 'green' : 'red'}>
        <div className={ `col-header-wrappper col-header-wrappper-border-${hasAValue ? '' : 'red'}`}>
          {hasAValue ? typeof text === 'string' ? <EllipsisText text={text} length={"14"} /> : text : ''}
          <a onClick={() => showEditMetaDataForm(record)}>
            <EditOutlined className='table-icon' />
          </a>
        </div>
      </Tooltip>
    )
  }

  const customHeadingRender = (col) => {
    return {
      className: 'col-heading-wrappper'
    };
  }

  const processDeleteSong = async (record) => {

    setDeletingLoading(true);
    const response = await deleteSong({
      userId: user.id,
      songPath: record.filePath
    })
    setDeletingLoading(false);

    if (response && response.ok){
      confirmAlert({
        title: 'Success',
        message: `Your song has been deleted`,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              window.location.reload()
            }
          }
        ]
      })
    } else {
      confirmAlert({
        title: 'Error',
        message: `Something went wrong. Please try again later.`,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
            }
          }
        ]
      })
    }
  }

  const showEditMetaDataForm = (record) => {
    const song = songs.find((song) => {return song.key === record.key})
    setSelectedSongPath(song.filePath)
    setSelectedSong(song)
    setSelectedAlbumTitle(song.albumTitle)
    setSelectedAlbumArtist(song.artist)
    setSelectedTrackNumber(song.trackNumber)
    setSelectedTrackName(song.trackName)
    setSelectedAlternateTrackName(song.alternateTrackName)
    setSelectedFeaturedArtists(song.featuredArtists)
    setSelectedSongGenre(song.genre ? song.genre[0].split(',') : [])
    setSelectedSongSubGenre(song.subGenre ? song.subGenre.split(',') : [])
    setSelectedLabel(song.label)
    setSelectedIsrc(song.isrc)
    setSelectedSongwriterAuthor(song.songwriterAuthor)
    setSelectedBeatmakerComposer(song.beatmakerComposer)
    setSelectedProducer(song.producer)
    setSelectedExecutiveProducer(song.executiveProducer)
    setSelectedEngineer(song.engineer)
    setSelectedMastering(song.mastering)
    setSelectedMixing(song.mixing)
    setSelectedLanguage(song.language)
    setSelectedCountryOfOrigin(song.countryOfOrigin)
    setSelectedReleaseDate(song.releaseDate)
    setSelectedDuration(song.duration)
    setSelectedMood(song.mood)
    setSelectedPublisher(song.publisher)
    showModal()

  }

  const columns = [
    {
      title: 'Album Artist',
      width: 200,
      dataIndex: 'artist',
      key: 'artist',
      ...(windowWidth > 991 ? {fixed: 'left'} : {}),
      className: 'table-heading',
      align: 'center',
      render: customColRender,
      onHeaderCell: customHeadingRender
    },
    {
      title: 'Track Name',
      width: 200,
      dataIndex: 'trackName',
      key: 'trackName',
      ...(windowWidth > 991 ? {fixed: 'left'} : {}),
      className: 'table-heading',
      align: 'center',
      render: customColRender,
      onHeaderCell: customHeadingRender
    },
    { title: 'Alternate Track Name', width: 200, dataIndex: 'alternateTrackName', key: 'alternateTrackName', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Album Title', width: 200, dataIndex: 'albumTitle', key: 'albumTitle', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Album Artist', width: 200, dataIndex: 'albumArtist', key: 'albumArtist', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Track Number', width: 200, dataIndex: 'trackNumber', key: 'trackNumber', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    // { title: 'title', dataIndex: 'title', key: 'title' },
    // { title: 'artist', dataIndex: 'artist', key: 'artist' },
    { title: 'Featured Artists', width: 200, dataIndex: 'featuredArtists', key: 'featuredArtists', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Genre', width: 200, dataIndex: 'genre', key: 'genre', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Sub-Genre', width: 200, dataIndex: 'subGenre', key: 'subGenre', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Label', width: 200, dataIndex: 'label', key: 'label', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Publisher', width: 200, dataIndex: 'publisher', key: 'publisher', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'ISRC', width: 200, dataIndex: 'isrc', key: 'isrc', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Songwriter/ Author', width: 200, dataIndex: 'songwriterAuthor', key: 'songwriterAuthor', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Beatmaker/ Composer', width: 200, dataIndex: 'beatmakerComposer', key: 'beatmakerComposer', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Producer', width: 200, dataIndex: 'producer', key: 'producer', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Executive Producer', width: 200, dataIndex: 'executiveProducer', key: 'executiveProducer', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Engineer', width: 200, dataIndex: 'engineer', key: 'engineer', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Mastering', width: 200, dataIndex: 'mastering', key: 'mastering', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Mixing', width: 200, dataIndex: 'mixing', key: 'mixing', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Language', width: 200, dataIndex: 'language', key: 'language', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Country of Origin', width: 200, dataIndex: 'countryOfOrigin', key: 'countryOfOrigin', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Release Date', width: 200, dataIndex: 'releaseDate', key: 'releaseDate', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Duration (s)', width: 200, dataIndex: 'duration', key: 'duration', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    { title: 'Mood', width: 200, dataIndex: 'mood', key: 'mood', className: 'table-heading', align: 'center', render: customColRender, onHeaderCell: customHeadingRender },
    {
      title: 'Action',
      key: 'operation',
      ...(windowWidth > 991 ? {fixed: 'right'} : {}),
      width: 100,
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          <a id={record.key} onClick={ async (e) => {
            confirmAlert({
              title: 'Please Note',
              message: `Are you sure you want to delete this song ? This action cannot be undone.`,
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {
                    processDeleteSong(record)
                  }
                },
                {
                  label: 'Cancel',
                  onClick: () => {
                  }
                }
              ]
            })
          }}>
            { deletingLoading ? <LoadingOutlined /> : <DeleteOutlined className='table-icon' /> }
          </a>
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  }

  const showBillingModal = () => {
    setIsModalVisible(false);
    setIsBillingModalVisible(true);
  }

  const handleOk = () => {
    setIsModalVisible(false);
    setIsBillingModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsBillingModalVisible(false);
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({...values,[name]: value})
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const updateListedSongs = (metadata, filePath) => {

    let nativeDataArray = Object.values(metadata.native).flat()
    let nativeDataObj = {}
    nativeDataArray.map(function(obj){
      nativeDataObj[obj.id] = obj.value
    })

    const publisher = nativeDataObj['TPUB'] ?? ''
    const isrc = nativeDataObj['TXXX:isrc'] ?? ''
    const songwriterAuthor = nativeDataObj['TXXX:writer'] ?? ''
    const countryOfOrigin = metadata.common.releasecountry ? metadata.common.releasecountry : nativeDataObj['TXXX:country'] ?? ''
    const mood = nativeDataObj['TXXX:mood'] ?? ''
    const alternateTrackName = nativeDataObj['TXXX:alternateTitle'] ?? ''
    const subGenre = nativeDataObj['TXXX:subGenre'] ?? ''
    const producer = metadata.common.producer ? metadata.common.producer : nativeDataObj['TXXX:producer'] ?? ''
    const executiveProducer = nativeDataObj['TXXX:executiveProducer'] ?? ''
    const engineer = nativeDataObj['TXXX:engineer'] ?? ''
    const mastering = nativeDataObj['TXXX:mastering'] ?? ''
    const mixing = nativeDataObj['TXXX:mixing'] ?? ''

    songs.push({
      key: songs.length + 1,
      albumTitle: metadata.common.album || '',
      albumArtist: metadata.common.albumartist || '',
      trackNumber: metadata.common.track.no || '',
      trackName: metadata.common.title || '',
      alternateTrackName: alternateTrackName || '',
      artist: metadata.common.artist || '',
      featuredArtists: metadata.common.artists || '',
      genre: metadata.common.genre || '',
      subGenre: subGenre || '',
      label: metadata.common.label || '',
      publisher: publisher ? publisher : '',
      isrc: isrc ? isrc : '',
      songwriterAuthor: songwriterAuthor ? songwriterAuthor : '',
      beatmakerComposer: metadata.common.composer || '',
      producer: producer ? producer : '',
      executiveProducer: executiveProducer ? executiveProducer : '',
      engineer: engineer ? engineer : '',
      mastering: mastering ? mastering : '',
      mixing: mixing ? mixing : '',
      language: metadata.common.language || '',
      countryOfOrigin: countryOfOrigin ? countryOfOrigin : '',
      releaseDate: metadata.common.date || '',
      duration: metadata.format.duration || '',
      mood: mood ? mood : '',
      bitrate: metadata.format.bitrate || '',
      codec: metadata.format.codec || '',
      codecProfile: metadata.format.codecProfile || '',
      container: metadata.format.container || '',
      numberOfSamples: metadata.format.numberOfSamples || '',
      sampleRate: metadata.format.sampleRate || '',
      filePath: filePath
    },
    )
    setSongs([...songs])
  }

  const renderSongs = async (file) => {
    setMetadataLoading(true)
    if (typeof file === 'string' || file instanceof String) {
      const metadata = await musicMetadata.fetchFromUrl(file, {duration: true});
      updateListedSongs(metadata, file)
    } else {
      musicMetadata.parseBlob(file).then(metadata => {
        updateListedSongs(metadata)
      });
    }
    setMetadataLoading(false)
  }

  const parseAudioFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    setUploadLoading(true)
    if (true) { // if you are a billing user
      // upload the song to AWS S3

      const albumKey = encodeURIComponent(user.uuid) + '/' + `${Date.now()}.mp3`;
      const albumBucketName = envKeys.awsMusicBucketName;

      const s3_params = {
        Bucket: albumBucketName, 
        Key: albumKey,
        Body: file,
        ContentType: 'audio/mp3'
      };
      const response = await client.send(new PutObjectCommand(s3_params));
      
      if (response && response['$metadata'] && response['$metadata'].httpStatusCode === 200 ) {
        // send request to server to persist song details
        const song_params = {
          userId: user.id,
          urlPath: albumKey
        }

        const response = await uploadSong(song_params)
        if (response && response.ok){
          const songPath = `${cloudFrontUrls.songs}${response.data.urlPath}`
          setUploadLoading(false)
          renderSongs(songPath)
        } else {
          // There was an error with uploading the song, show popup
        }
      } else {
        // There was an error with uploading the song to s3
      }
    } else {
      setUploadLoading(false)
      renderSongs(options.file)
    }
  }

  const handleMetaDataUpdate = async () => {
    const params = {
      userId: user.id,
      songPath: selectedSongPath,
      ...(selectedAlbumArtist ? { artist: selectedAlbumArtist } : { artist: '' }),
      ...(selectedTrackName ? { title: selectedTrackName } : { title: '' }),
      ...(selectedAlternateTrackName ? { alternateTitle: selectedAlternateTrackName } : { title: '' }),
      ...(selectedSongGenre ? { genre: selectedSongGenre } : { genre: [] }),
      ...(selectedSongSubGenre ? { subGenre: selectedSongSubGenre } : { subGenre: [] }),
      ...(selectedReleaseDate ? { releaseDate: selectedReleaseDate } : { releaseDate: '' }),
      ...(selectedAlbumTitle ? { album: selectedAlbumTitle } : { album: '' }),
      ...(selectedAlbumDescription ? { description: selectedAlbumDescription } : { description: '' }),
      ...(selectedTrackNumber ? { track: selectedTrackNumber } : { track: '' }),
      ...(selectedFeaturedArtists ? { albumArtist: Array.isArray(selectedFeaturedArtists) ? selectedFeaturedArtists.join(', ') : selectedFeaturedArtists } : { albumArtist: '' }),
      ...(selectedBeatmakerComposer ? { composer: Array.isArray(selectedBeatmakerComposer) ? selectedBeatmakerComposer.join(', ') : selectedBeatmakerComposer } : { composer: '' }),
      ...(selectedProducer ? { producer: selectedProducer } : { producer: '' }),
      ...(selectedExecutiveProducer ? { executiveProducer: selectedExecutiveProducer } : { executiveProducer: '' }),
      ...(selectedEngineer ? { engineer: selectedEngineer } : { engineer: '' }),
      ...(selectedMastering ? { mastering: selectedMastering } : { mastering: '' }),
      ...(selectedMixing ? { mixing: selectedMixing } : { mixing: '' }),
      ...(selectedLabel ? { label: Array.isArray(selectedLabel) ? selectedLabel.join(', ') : selectedLabel } : { label: '' }),
      ...(selectedLanguage ? { language: selectedLanguage } : { language: '' }),
      ...(selectedPublisher ? { publisher: selectedPublisher } : { publisher: '' }),
      ...(selectedIsrc ? { isrc: selectedIsrc } : { isrc: '' }),
      ...(selectedSongwriterAuthor ? { writer: selectedSongwriterAuthor } : { writer: '' }),
      ...(selectedCountryOfOrigin ? { country: selectedCountryOfOrigin } : { country: '' }),
      ...(selectedMood ? { mood: selectedMood } : { mood: '' }),
    }

    setMetadataSubmitting(true)
    const response = await updateSong(params)
    setMetadataSubmitting(false)

    if (response && response.ok){
      hideModal()
      confirmAlert({
        title: 'Success',
        message: `Your Meta Data has been saved`,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              window.location.reload()
            }
          }
        ]
      })
    } else {
      hideModal()
      confirmAlert({
        title: 'Error',
        message: response.error || 'Something went wrong',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {}
          }
        ]
      })
    }
  }

  const secondsToMins = (s) => {return(s-(s%=60))/60+(9<s?'min':'min0')+parseFloat(s).toFixed(0)}

  useEffect(() => {
    setSongs(songs)
  }, [songs])

  return (
    <div>
      <Modal footer={false} width={1000} title="" footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <center>
          <h2 className="text-dark font-weight-bold mb-2"> Meta Data</h2>
        </center>
        <br/>
        <br/>
        <center>
          <Divider>
            <h4>Song Details</h4>
          </Divider>
        </center>
        <br/>
        <br/>
        <div className="row">
          <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Album Title</label>
            <Input disabled={false} size="large" placeholder="Album Title" value={selectedAlbumTitle} onChange={(e) => { setSelectedAlbumTitle(e.target.value) }} />
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Album Artist</label>
            <Input disabled={false} size="large" placeholder="Album Artist" value={selectedAlbumArtist} onChange={(e) => { setSelectedAlbumArtist(e.target.value) }} />
          </div>
        </div>
        <br/>
        <div className="row">
        <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Track Number</label>
            <Input type="number" disabled={false} size="large" placeholder="Track Number" value={selectedTrackNumber} onChange={(e) => { setSelectedTrackNumber(e.target.value) }} />
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Track Name</label>
            <Input disabled={false} size="large" placeholder="Track Name" value={selectedTrackName} onChange={(e) => { setSelectedTrackName(e.target.value) }} />
          </div>
        </div>
        <br/>
        <div className="row">
        <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Alternate Track Name</label>
            <Input disabled={false} size="large" placeholder="Alternate Track Name" value={selectedAlternateTrackName} onChange={(e) => { setSelectedAlternateTrackName(e.target.value) }} />
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Featured Artists</label>
            <Input disabled={false} size="large" placeholder="Artists" value={selectedFeaturedArtists} onChange={(e) => { setSelectedFeaturedArtists(e.target.value) }}/>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Genre</label>
            <Select mode="multiple" style={{ width: '100%' }} allowClear={false} size="large" placeholder="Please select a genre" value={selectedSongGenre} onChange={(value) => { 
              setSelectedSongGenre(value)
              }}>
              {GenreOptions}
            </Select>
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            <label className='meta-data-input-label'>Sub-Genre</label>
            <Select mode="multiple" style={{ width: '100%' }} allowClear={false} size="large" placeholder="Please select a sub-genre" value={selectedSongSubGenre} onChange={(value) => { 
              setSelectedSongSubGenre(value)
              }}>
              {GenreOptions}
            </Select>
          </div>
        </div>
        <br/>
        <br/>
        <center>
          <Divider>
            <h4>Label/Publishing Details</h4>
          </Divider>
        </center>
        <br/>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <label className='meta-data-input-label'>Label</label>
            <Input disabled={false} size="large" placeholder="Label" value={selectedLabel} onChange={(e) => { setSelectedLabel(e.target.value) }} />
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Publisher</label>
            <Input disabled={false} size="large" placeholder="Publisher" value={selectedPublisher} onChange={(e) => { setSelectedPublisher(e.target.value) }}/>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <label className='meta-data-input-label'>ISRC</label>
            <Input disabled={false} size="large" placeholder="ISRC" value={selectedIsrc} onChange={(e) => { setSelectedIsrc(e.target.value) }} />
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            <label className='meta-data-input-label'>Songwriter/ Author</label>
            <Input disabled={false} size="large" placeholder="Songwriter/ Author" value={selectedSongwriterAuthor} onChange={(e) => { setSelectedSongwriterAuthor(e.target.value) }} />
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Beatmaker/ Composer</label>
            <Input disabled={false} size="large" placeholder="Beatmaker/ Composer" value={selectedBeatmakerComposer} onChange={(e) => { setSelectedBeatmakerComposer(e.target.value) }} />
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            <div>
              {/* GOT */}
              <label className='meta-data-input-label'>Producer</label>
              <Input disabled={false} size="large" placeholder="Producer" value={selectedProducer} onChange={(e) => { setSelectedProducer(e.target.value) }} />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6">
            <div>
              {/* GOT */}
              <label className='meta-data-input-label'>Executive Producer</label>
              <Input disabled={false} size="large" placeholder="Executive Producer" value={selectedExecutiveProducer} onChange={(e) => { setSelectedExecutiveProducer(e.target.value) }} />
            </div>
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            <div>
              {/* GOT */}
              <label className='meta-data-input-label'>Engineer</label>
              <Input disabled={false} size="large" placeholder="Engineer" value={selectedEngineer} onChange={(e) => { setSelectedEngineer(e.target.value) }} />
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <div>
              {/* GOT */}
              <label className='meta-data-input-label'>Mastering</label>
              <Input disabled={false} size="large" placeholder="Mastering" value={selectedMastering} onChange={(e) => { setSelectedMastering(e.target.value) }} />
            </div>
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Mixing</label>
            <Input disabled={false} size="large" placeholder="Mixing" value={selectedMixing} onChange={(e) => { setSelectedMixing(e.target.value) }}/>
          </div>
        </div>
        <br/>
        <br/>
        <center>
          <Divider>
            <h4>Other Details</h4>
          </Divider>
        </center>
        <br/>
        <br/>
        <div className="row">
          <div className="col-md-6">
            {/* GOT */}
            <label className='meta-data-input-label'>Language</label>
            <Input disabled={false} size="large" placeholder="Language" value={selectedLanguage} onChange={(e) => { setSelectedLanguage(e.target.value) }}/>
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            <label className='meta-data-input-label'>Country of Origin</label>
            <Select style={{ width: '100%' }} allowClear={false} size="large" placeholder="Please select a country" options={countries} value={selectedCountryOfOrigin} onChange={(value) => { 
              setSelectedCountryOfOrigin(value)
              }}/>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <label className='meta-data-input-label'>Release Date</label>
            <DatePicker style={{ width: '100%' }} disabled={false} size="large" placeholder="Release Date" value={ selectedReleaseDate ? moment(selectedReleaseDate) : ''} onChange={(date, dateString) => { setSelectedReleaseDate(dateString) }}/>
          </div>
          <br/>
          <br/>
          <div className="col-md-6">
            <label className='meta-data-input-label'>Duration</label>
            <Input disabled={true} size="large" placeholder="Duration" value={selectedDuration && secondsToMins(selectedDuration)} onChange={(e) => { setSelectedDuration(e.target.value) }} />
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-6">
            <label className='meta-data-input-label'>Mood</label>
            <Input disabled={false} size="large" placeholder="Mood" value={selectedMood} onChange={(e) => { setSelectedMood(e.target.value) }}/>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <center>
          <Button onClick={() => {
            handleMetaDataUpdate()
          }} type="primary" shape="round" size={'large'} className="btn btn-primary atn-button btn-with-icon">
            { metadataSubmitting && <LoadingOutlined /> }
            { metadataSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </center>
        <br/>
        {/* <br/>
        <Button type="default" block> Download </Button>
        <br/> */}
        <br/>
      </Modal>

      <div className="row align-items-center">
        <div className="col-lg-6 offset-lg-3 col-md-12">
          { uploadLoading ? 
            <center>
              <LoadingOutlined style={{ fontSize: 24 }} spin /> 
              <br/>
              <br/>
              <h4>Processing...</h4>
              <br/>
            </center>
            :
            <Dragger
              customRequest={parseAudioFile}
              multiple={false}
              showUploadList={false}
              progress={true}
              >
              <br/>
              <br/>
              <br/>
              <p className="ant-upload-text">Click or drag an audio file to this area to see the MetaData</p>
              <p className="ant-upload-hint">
                Once you have added the file(s) you will see the meta data for the file(s)
              </p>
              <br/>
              <br/>
              <br/>
            </Dragger>
          }
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="row align-items-center">
        <div className="col-lg-12 col-md-12">
          { !metadataLoading && songs.length == 0 ?
            <div>
            </div>
            :
            <div>
              { 
                metadataLoading ?
                <center>
                  <LoadingOutlined style={{ fontSize: 24 }} spin /> 
                  <br/>
                  <br/>
                  <h4>Generating Metadata...</h4>
                  <br/>
                </center>
                :
                <div>
                  <Table 
                    bordered={true}
                    pagination={false}
                    columns={columns}
                    dataSource={songs}
                    scroll={{ x: 1300 }}
                  />
                  <br/>
                  <br/>
                  <CSVLink data={songs} style={{color: '#DC143C'}}>
                    Download Spreedsheet
                  </CSVLink>
                </div>

              }
              {/* <Button type="primary" shape="round" icon={<DownloadOutlined />} size='small'>
                Download
              </Button> */}
            </div>
          }
          <br/>
          <br/>
        </div>
      </div>
    </div>
  )
}

export default MetaDataForm