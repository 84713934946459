import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SubscribeModal from '../components/SubscribeModal'
import InviteModal from '../components/InviteModal'
import { getAllVideos } from '../services/videoService'
import { getVideoProgress } from '../services/videoProgressService'
import VideoSummary from '../components/VideoSummary'
import VideoProgressSummary from '../components/VideoProgressSummary'
import { getFromStorage, removeFromStorage, getLatestUser } from '../helpers/appHelpers'
import { acceptSplit, getAllSplits } from '../services/splitService'
import { confirmAlert } from 'react-confirm-alert';
import { Redirect } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import HeaderCard from '../components/HeaderCard';
import { isPayingCustomer, subscriptionDaysLeft } from '../helpers/appHelpers'
import { Header } from 'antd/lib/layout/layout';

const DashboardPage = (props) => {
  const [user, setUser] = useState({});
  const [subscribeModalVisible, setSubscribeModalVisible] = useState(false);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [invitedSplit, setInvitedSplit] = useState({});
  const [videosLoading, setVideosLoading] = useState(false);
  const [videoProgressLoading, setVideoProgressLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [videoProgress, setVideoProgress] = useState({})
  const [redirect, setRedirect] = useState(false);
  const [profileRedirectt, setProfileRedirect] = useState(false)
  const isSubscriptionActive = isPayingCustomer(user)

  async function fetchVideos() {
    setVideosLoading(true)
    const response = await getAllVideos({
      userId: user.id
    })
    setVideosLoading(false)
    if (response && response.ok) {
      const sortedVideos = response.data.sort(function (a, b) { return a.order - b.order })
      setVideos(sortedVideos)
    }
    else {
      console.log(response.error)
    }
  }

  async function fetchVideoProgress() {
    setVideoProgressLoading(true)
    const response = await getVideoProgress({
      userId: user.id
    })
    setVideoProgressLoading(false)
    if (response && response.ok) {
      setVideoProgress(response.data.progress)
    }
    else {
      console.log(response.error)
    }
  }

  useEffect(() => {
    const loggedInUser = getFromStorage('user')
    const splitObj = getFromStorage('invitedSplit')
    if (splitObj) {
      setInvitedSplit(splitObj)
      acceptSplitInvitation(splitObj)
    }
    if (loggedInUser) {
      getLatestUser(loggedInUser)
      const latestUser = getFromStorage('user')
      setUser(latestUser)
    }
    else { setRedirect(true) }
    fetchVideos()
    fetchVideoProgress()
  }, [user.firstName, invitedSplit.splitUuid, invitedSplit.composerUuid, JSON.stringify(videos), Object.keys(videoProgress).length])

  async function acceptSplitInvitation(pendingSplit) {
    const params = {
      userId: user.id,
      splitUuid: pendingSplit.splitUuid,
      composerUuid: pendingSplit.composerUuid
    }
    const response = await acceptSplit(params)
    if (response && response.ok) {
      confirmAlert({
        title: 'Success',
        message: 'You have successfully accepted the split invitation',
        buttons: [
          {
            label: 'Ok',
            onClick: () => { }
          }
        ]
      })
      removeFromStorage('invitedSplit')
    }
    else {
      console.log("ERROR =====>", response.error)
    }
  }

  if (redirect) {
    return <Redirect push to="/" />
  }

  if (profileRedirectt) {
    return <Redirect push to="/users/profile" />
  }

  return (
    <div>
      <SubscribeModal user={user} isVisible={subscribeModalVisible} onPressClose={() => setSubscribeModalVisible(false)} />
      <InviteModal user={user} isVisible={inviteModalVisible} onPressClose={() => setInviteModalVisible(false)} />
      <Navbar
        isDefaultSticky={true}
        isLoggedIn={true}
      />
      <section className="dashboard-area">
        {
          !isSubscriptionActive ? <HeaderCard
            heading='💪 Master Of The Industry'
            body={['Do you want access to all of our content ?', 'Invite your friend to use MOTI and we will give you free access to all our content once your friend signs up!']}
            imageUrl={'https://d3v0zkcslccxqy.cloudfront.net/logo.png'}
            onActionPress={() => {
              setInviteModalVisible(true)
            }}
            buttonTitle={'Invite Friend'}
            buttonIcon={'subscribe'}
          />
            :
            <HeaderCard
              heading='💪 Master Of The Industry'
              body={
                user && isSubscriptionActive ?
                  ["Welcome to M.O.T.I", "Everyone deserves a M.O.T.I on their music career journey, one should never be afraid to earn what is rightfully theirs.", "We hope you enjoy the exclusive content."]
                  :
                  ["We see you have not yet subscribed to our paid plan.", "Subscribe now and reap the benefits with access to exclusive features and content!"]
              }
              imageUrl='https://d3v0zkcslccxqy.cloudfront.net/logo.png'
              downloadUrl="https://d3v0zkcslccxqy.cloudfront.net/moti-b-plan.pdf"
              onActionPress={() => {
                user && isSubscriptionActive ?
                  setProfileRedirect(true)
                  :
                  setSubscribeModalVisible(true)
              }}
              hideActionButton={user && isSubscriptionActive}
              buttonTitle={
                user && isSubscriptionActive ?
                  'Manage Subscription'
                  :
                  'Subscribe Now'
              }
              buttonIcon={
                user && isSubscriptionActive ?
                  'manage'
                  :
                  'subscribe'
              }
              countDownDays={
                subscriptionDaysLeft(user)
              }
            />
        }
        <br />
        <VideoProgressSummary
          videos={videos}
          userVideoProgress={videoProgress}
        />
        <br />
        <br />
        {videosLoading ?
          <div>
            <br />
            <h2 className="text-dark font-weight-bold mb-2"> Watch Now</h2>
            <LoadingOutlined style={{ fontSize: 24 }} spin />
            <br />
          </div>
          :
          < VideoSummary videos={videos} videoProgress={videoProgress} onSubscribePress={() => setSubscribeModalVisible(true)} />
        }
        < br />
        < br />
        < br />
        < br />
      </section>
      <Footer />
    </div>
  )
}

export default DashboardPage