import React, { useEffect, useState } from 'react'
import { Button, Modal, Input } from 'antd';
import { Progress } from 'antd';
import EllipsisText from "react-ellipsis-text";

const VideoProgressSummary = (props) => {
  const { videos, userVideoProgress } = props
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }} className='moti-video-progress-wrapper'>
      <div className='video-progress-wrapper'>
        <h2 className="text-dark heading-progress font-weight-bold mb-2">Welcome Back</h2>
        <p className="sub-heading-progress">Please see your video content progress below</p>
        < br/>
        <div className="videos-wrapper row">

          {videos.map((video) => {
            if(video && userVideoProgress[video.id]){
              return(
                <div className="video-progress-content-wrapper col-lg-6 col-md-6">
                  <Progress
                    width={150}
                    type="circle"
                    strokeColor={{
                      '0%': '##F76D89',
                      '100%': '#DC143C',
                    }}
                    percent={Math.round(userVideoProgress[video.id].progress * 100)}
                  />
                  <p className="video-progress-title">{video.title.toUpperCase()}</p>
                  <p className="video-progress-subtitle">
                    <EllipsisText text={video.description} length={"60"} />
                  </p>
                </div>
              )
            }
          })}

        </div>
        < br/>
        < br/>
      </div>
    </div>
  );
}

export default VideoProgressSummary;