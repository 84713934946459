import { endpoints } from '../utilities/config'

async function uploadSong(params) {
  const url = endpoints.songs.upload
  const formData  = new FormData();
  for(const key in params) {
    formData.append(key, params[key]);
  }

  const response = await fetch(url, {
    method: 'POST',
    body: formData
  })
  return response.json()
}

async function updateSong(params) {
  const url = endpoints.songs.update
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params)
  })
  return response.json()
}

async function getAllSongs(params) {
  const url = endpoints.songs.getAll.replace(':userId', params.userId)
  const response = await fetch(url, {
    method: 'GET'
  })
  return response.json()
}

async function deleteSong(params) {
  const url = endpoints.songs.delete
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params)
  })
  return response.json()
}

export {
  uploadSong,
  updateSong,
  getAllSongs,
  deleteSong
}