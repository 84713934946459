import React, { useEffect, useState } from 'react';
import { Modal, Divider, Button } from 'antd'
import { Redirect } from 'react-router-dom';
import { usePaystackPayment } from 'react-paystack';
import { envKeys } from '../utilities/config'

const SubscribeModal = (props) => {
  const { isVisible, onPressClose, user } = props
  const [successRedirect, setSuccessRedirect] = useState(false)
  const [failureRedirect, setFailureRedirect] = useState(false)

  const renderPayButton = () => {
    const config = {
      reference: (new Date()).getTime().toString(),
      email: user.emailAddress,
      amount: 23000,
      publicKey: envKeys.paystackKey,
      currency: 'ZAR',
      channels: ['card'],
      // plan: 'PLN_liuukmi6ey1ji2q'
    }
    const initializePayment = usePaystackPayment(config);
    return (
      <div>
        <Button disabled={false} type="primary" shape="round" size={'large'} className="btn btn-primary" onClick={() => {
          initializePayment(onSuccess, onClose)
        }}>
          Pay Now
        </Button>
      </div>
    );
  }

  const onSuccess = (reference) => {
    if (reference && reference.status === 'success') {
      return setSuccessRedirect(true)
    }
  };

  const onClose = () => {
    console.log('closed')
  }

  return (
    <Modal
      centered
      footer={false}
      visible={isVisible}
      onOk={() => {
        onPressClose()
      }}
      onCancel={() => {
        onPressClose()
      }}
      destroyOnClose={true}
      width={1000}
    >
      {successRedirect && <Redirect push to="/success" />}
      {failureRedirect && <Redirect push to="/error" />}
      <div>
        <h3 className="text-dark font-weight-bold mb-2">Unlimited access to all features and content</h3>
        <Divider />
        < br />
        <center>
          <img src='https://d3v0zkcslccxqy.cloudfront.net/logo.png' style={{ width: 300, height: 'auto' }} />
          < br />
          <h4 className="text-dark font-weight-bold mb-2">$14.99/month</h4>
          < br />
          <p className="text-dark font-weight-bold mb-2">No commitment required. Cancel anytime</p>
        </center>
        < br />
        <p className="text-dark mb-2">
          This is an offer for a MOTI Digital Access Subscription. Your payment method will automatically
          be charged in advance every month. You will be charged $14.99 every month until you cancel. Your
          subscription will continue until you cancel. You can cancel anytime. Cancellations take effect at
          the end of your current billing period. The MOTI Digital Access Subscription includes
          access to all videos, Ask a MOTI, downloading your Music Meta Data and Digital Splitsheets.
          Offers and pricing are subject to change without notice
        </p>
        <p className="text-dark mb-2">
          <strong>
            After payment has been received, we will send you a payment confirmation email. This typically takes about 20 seconds.
          </strong>
        </p>
        < br />
        <center>
          {renderPayButton()}
        </center>
      </div>
    </Modal>
  );
}

export default SubscribeModal;
