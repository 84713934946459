import React, { Component } from 'react';
import AboutImage from "images/logo.png"
import CirleImage from "images/circle.png"
class ComingSoon extends Component {
	render() {
		return (
			<section id="about" className="about-area ptb-100">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="poster-wrapper">
                  <img src={AboutImage} alt="image" className="poster-img" />
                  <p className="poster-sub-heading">PODCAST</p>
                  <p className="poster-heading">MOTI CAST</p>
                  <p className="poster-text">A podcast dedicated to discussing all things music related and it's impact on Africa</p>
                  <br/>
                  {/* <a href="/signup" className="btn btn-primary btn-orange">Sign Up Here</a> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="poster-wrapper">
                  <img src={AboutImage} alt="image" className="poster-img" />
                  <p className="poster-sub-heading">BLOG/VLOG</p>
                  <p className="poster-heading">MOTI VLOG</p>
                  <p className="poster-text">A blog dedicated to the latest news, views, and industry updates with a focus on Africa</p>
                  <p></p>
                </div>
              </div>
            </div>
						</div>

						<div className="col-lg-6 col-md-12">
							<div className="about-content pl-15">
								<h2 className="about-heading">COMING SOON</h2>
								<p>
                  Our pan-African workshops hosting various different 
                  MOTI's from across the continent will serve as a hub 
                  to share their industry expertise. 
                </p>
                <br/>
                <p>
                  You can look forward to upcoming conferences featuring 
                  performances by emerging artists, bubbling under 
                  artists and established acts.
                </p>
                <br/>
                <p>
                  We look forward to offering an intensive MOTI Music 
                  Business certification that is globally recognised 
                  as well as competitive internship programs across 
                  our stakeholder chain.
                </p>
                <br/>
                <p>
                  Our digital community will expand to The Afro Digital Podcast brought to you by MOTI
                </p>
                <br/>
                <p>
                  Join the journey!
                </p>
								<a href="/signup" className="btn btn-primary btn-orange">Sign Up Here</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default ComingSoon;
