import React, { useEffect, useState, useMemo } from 'react';
import Footer from '../components/Footer';
import { Redirect } from 'react-router-dom';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Select } from 'antd';
import { Button } from 'antd';
import { userRoles } from '../utilities/config';
import {
	validateUsername,
	validateName,
	validateEmail,
	validatePassword
} from '../utilities/validators';
import { registerUser } from '../services/userService'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { setInStorage } from '../helpers/appHelpers'
import queryString from 'query-string'
import { getFromStorage } from '../helpers/appHelpers'
import countryList from 'react-select-country-list';


const { Option } = Select;

const SignupPage = (props) => {
	const countries = useMemo(() => countryList().getData(), [])
	const [role, setRole] = useState('')
	const [isRoleValid, setIsRoleValid] = useState(false)
	const [userName, setUserName] = useState('')
	const [isUserNameValid, setIsUserNameValid] = useState(false)
	const [firstName, setFirstName] = useState('')
	const [isFirstNameValid, setIsFirstNameValid] = useState(false)
	const [lastName, setLastName] = useState('')
	const [isLastNameValid, setIsLastNameValid] = useState(false)
	const [email, setEmail] = useState('')
	const [isEmailValid, setIsEmailValid] = useState(false)
	const [password, setPassword] = useState('')
	const [isPasswordValid, setIsPasswordValid] = useState(false)
	const [canSubmitForm, setCanSubmitForm] = useState(false)
	const [isFormLoading, setIsFormLoading] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [loggedInRedirect, setLoggedInRedirect] = useState(false)
	const [isCountryValid, setIsCountryValid] = useState(false);
	const [countryCode, setCountryCode] = useState('')
	const [country, setCountry] = useState('');

	useEffect(() => {
		const isFormValid =
			isRoleValid &&
			isUserNameValid &&
			isFirstNameValid &&
			isLastNameValid &&
			isEmailValid &&
			isPasswordValid &&
			isCountryValid
		setCanSubmitForm(isFormValid)
	}, [isRoleValid,
		isUserNameValid,
		isFirstNameValid,
		isLastNameValid,
		isEmailValid,
		isPasswordValid,
		isCountryValid
	])

	useEffect(() => {
		const queryParams = queryString.parse(props.location.search)
		setInStorage({
			key: 'invitedSplit',
			value: {
				splitUuid: queryParams.s,
				composerUuid: queryParams.c
			}
		})
	}, [])

	useEffect(() => {
		const queryParams = queryString.parse(props.location.search)
		if (queryParams && queryParams.uuid) {
			setInStorage({
				key: 'uuid',
				value: queryParams.uuid,
			})
		}
	}, [])

	useEffect(() => {
		const loggedInUser = getFromStorage('user')
		if (loggedInUser) {
			setLoggedInRedirect(true)
		}
		else {
			// do nothing
		}
	}, [])

	function handleRole(value) {
		const isValid = userRoles.includes(value)
		setIsRoleValid(isValid)
		setRole(value)
	}

	function handleUserName(e) {
		const value = e.target.value
		const isValid = validateUsername(value)
		setIsUserNameValid(isValid)
		setUserName(value)
	}

	function handleFirstName(e) {
		const value = e.target.value
		const isValid = validateName(value)
		setIsFirstNameValid(isValid)
		setFirstName(value)
	}

	function handleLastName(e) {
		const value = e.target.value
		const isValid = validateName(value)
		setIsLastNameValid(isValid)
		setLastName(value)
	}

	function handleEmail(e) {
		const value = e.target.value
		const isValid = validateEmail(value)
		setIsEmailValid(isValid)
		setEmail(value)
	}

	function handleCountry(value) {
		const countryLabels = countryList().getLabels()
		const countryLabel = countryList().getLabel(value)
		const isValid = countryLabels.includes(countryLabel)
		setIsCountryValid(isValid)
		setCountry(countryLabel)
		setCountryCode(value)

	}

	function handlePassword(e) {
		const value = e.target.value
		const isValid = validatePassword(value)
		setIsPasswordValid(isValid)
		setPassword(value)
	}

	async function handleFormSubmission() {
		const formParams = {
			emailAddress: email.toLowerCase(),
			password,
			firstName: firstName.toLowerCase(),
			lastName: lastName.toLowerCase(),
			userName: userName.toLowerCase(),
			country: country.trim(),
			countryCode: countryCode.toUpperCase().trim(),
			role: role.toLowerCase(),
			inviteUuid: await getFromStorage('uuid') || '',
		}

		setIsFormLoading(true)
		const response = await registerUser(formParams)
		setIsFormLoading(false)

		if (response && response.ok) {
			setInStorage({
				key: 'user',
				value: response.data
			})
			setRedirect(true)
		}
		else {
			confirmAlert({
				title: 'Error',
				message: response.error,
				buttons: [
					{
						label: 'Ok',
						onClick: () => { }
					}
				]
			})
		}
	}

	if (redirect) {
		return <Redirect push to="/dashboard" />
	}

	if (loggedInRedirect) {
		return <Redirect push to="/dashboard" />
	}

	return (
		<section className="signup-area">
			<div className="d-table">
				<div className="d-table-cell">
					<div className="signup-form">
						<h3>Create your Account</h3>

						<form>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label>Username</label>
										<Input style={isUserNameValid ? {} : { borderColor: '#B22222' }} value={userName} onChange={handleUserName} className="form-control" placeholder="" />
										<p className='form-error-msg'>{!isUserNameValid ? 'Please enter a valid UserName' : ''}</p>
									</div>
								</div>

								<div className="col-md-6">
									<div className="form-group">
										<label>Role</label>
										<Select size={'large'} bordered={false} style={isRoleValid ? {} : { borderColor: '#B22222' }} defaultValue="" className="form-control" onChange={handleRole}>
											<Option value="performing-artist">Performing Artist</Option>
											<Option value="songwriter">Songwriter</Option>
											<Option value="producer">Producer</Option>
											<Option value="sound-engineer">Sound Engineer</Option>
											<Option value="publisher">Publisher</Option>
											<Option value="aggregator">Aggregator</Option>
											<Option value="session-musician">Session Musician</Option>
											<Option value="cd-manufacturer">CD Manufacturer</Option>
											<Option value="booking-agent">Booking Agent</Option>
											<Option value="road-manager">Road Manager</Option>
											<Option value="business-manager">Business Manager</Option>
											<Option value="promoter">Promoter</Option>
											<Option value="tour-manager">Tour Manager</Option>
											<Option value="videographer">Videographer</Option>
											<Option value="photographer">Photographer</Option>
											<Option value="public-relations">Public Relations</Option>
											<Option value="disabled" disabled>
												Disabled
											</Option>
										</Select>
										<p className='form-error-msg'>{!isRoleValid ? 'Please enter a valid role' : ''}</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label>First Name</label>
										<Input style={isFirstNameValid ? {} : { borderColor: '#B22222' }} value={firstName} onChange={handleFirstName} className="form-control" placeholder="" />
										<p className='form-error-msg'>{!isFirstNameValid ? 'Please enter a valid FirstName' : ''}</p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label>Last Name</label>
										<Input style={isLastNameValid ? {} : { borderColor: '#B22222' }} value={lastName} onChange={handleLastName} className="form-control" placeholder="" />
										<p className='form-error-msg'>{!isLastNameValid ? 'Please enter a valid LastName' : ''}</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label>Email</label>
										<Input style={isEmailValid ? {} : { borderColor: '#B22222' }} value={email} onChange={handleEmail} className="form-control" placeholder="" />
										<p className='form-error-msg'>{!isEmailValid ? 'Please enter a valid Email' : ''}</p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label>Password</label>
										<Input.Password
											className="form-control"
											style={isPasswordValid ? {} : { borderColor: '#B22222' }}
											placeholder=""
											onChange={handlePassword}
											iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
										/>
										<p className='form-error-msg'>{!isPasswordValid ? 'Must be at least 6 characters' : ''}</p>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label>Country</label>
										<Select size={'large'} bordered={false} style={isCountryValid ? {} : { borderColor: '#B22222' }} defaultValue="" options={countries} value={country} className="form-control" onChange={handleCountry} />
										<p className='form-error-msg'>{!isCountryValid ? 'Please enter a valid country' : ''}</p>
									</div>
								</div>
							</div>

							{/* <Link to="/dashboard" className="btn btn-primary">Signup</Link> */}
							<br />
							<Button disabled={!canSubmitForm} type="primary" shape="round" size={'large'} className="btn btn-primary atn-button" loading={isFormLoading} onClick={handleFormSubmission}>
								Signup
							</Button>

							<p>Already a registered user? <a href="/signin">Signin!</a></p>
						</form>
					</div>
				</div>
			</div>
			< Footer />
		</section>
	);
}

export default SignupPage;
