import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import FacebookImg from "images/moti_fb.png"
import TwitterImg from "images/moti_twitter.png"
import IGImg from "images/moti_ig.png"

class News extends Component {
	render() {
		return (
			<section id="features" className="features-area ptb-100 bg-f9f9f9">
				<div className="container">
					<div className="section-title">
						<h1>MOTI SOCIALS</h1>
						<br/>
						<p>Join the tribe!</p>
            <br/>
            <p>
              Follow MOTI on all social media platforms to get all the 
              latest news and behind the scenes action from industry 
              events to exclusive content access.
            </p>
						<br/>
					</div>

					<Carousel
						autoPlay={true}
						infiniteLoop={true}
						interval={5000}
						showStatus={false}
						showThumbs={false}
					>
						<img className="social-screenshot" src={FacebookImg} />
            <img className="social-screenshot" src={TwitterImg} />
            <img className="social-screenshot" src={IGImg} />

					</Carousel>
				</div>
			</section>
		);
	}
}

export default News;
