import React, { useEffect, useState } from 'react';
import { Modal, Divider, Button } from 'antd'
import { Redirect } from 'react-router-dom';
import { Input } from 'antd';
import { validateEmail } from '../utilities/validators';
import { inviteFriend } from '../services/userService'
import { confirmAlert } from 'react-confirm-alert';

const InviteModal = (props) => {
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [canSubmitForm, setCanSubmitForm] = useState(false)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [email, setEmail] = useState('')
  const { isVisible, onPressClose, user } = props
  const [successRedirect, setSuccessRedirect] = useState(false)

  const onClose = () => {
    console.log('closed')
  }

  useEffect(() => {
    const isFormValid =
      !!isEmailValid
    setCanSubmitForm(isFormValid)
  }, [isEmailValid])

  function handleEmail(e) {
    const value = e.target.value
    const isValid = validateEmail(value)
    setIsEmailValid(isValid)
    setEmail(value)
  }

  async function handleFormSubmission() {
    const formParams = {
      emailAddress: email.toLowerCase(),
      userId: user.id
    }

    setIsFormLoading(true)
    const response = await inviteFriend(formParams)
    setIsFormLoading(false)

    if (response && response.ok) {
      onPressClose()

      return confirmAlert({
        title: 'Success',
        message: "We have emailed your invite to your friend!",
        buttons: [
          {
            label: 'Ok',
            onClick: () => { }
          }
        ]
      })
    }
    else {
      confirmAlert({
        title: 'Error',
        message: response.error,
        buttons: [
          {
            label: 'Ok',
            onClick: () => { }
          }
        ]
      })
    }
  }

  return (
    <Modal
      centered
      footer={false}
      visible={isVisible}
      onOk={() => {
        onPressClose()
      }}
      onCancel={() => {
        onPressClose()
      }}
      destroyOnClose={true}
      width={1000}
    >
      <div>
        <h3 className="text-dark font-weight-bold mb-2">Invite your friend</h3>
        <Divider />
        < br />
        <center>
          <img src='https://d3v0zkcslccxqy.cloudfront.net/logo.png' style={{ width: 300, height: 'auto' }} />
          < br />
          <h4 style={{ textDecoration: 'line-through' }} className="text-dark font-weight-bold mb-2">$14.99/month</h4>
          < br />
        </center>
        < br />
        <p className="text-dark mb-2">
          This is an offer for a MOTI Digital Access Subscription. The MOTI Digital Access Subscription includes
          access to all videos, Ask a MOTI, downloading your Music Meta Data and Digital Splitsheets.
          Invite your friend below to get access to all of our content! You will be granted access once your friend has
          registered for our platform!
        </p>

        <br />
        <div className='invite-form-wrapper'>
          <div className="form-group">
            <label>Email</label>
            <Input style={isEmailValid ? {} : { borderColor: '#B22222' }} value={email} onChange={handleEmail} className="form-control" placeholder="" />
            <p className='form-error-msg'>{!isEmailValid ? 'Please enter a valid Email' : ''}</p>
          </div>
        </div>
        <Button disabled={!canSubmitForm} type="primary" shape="round" size={'large'} className="btn btn-primary atn-button" loading={isFormLoading} onClick={handleFormSubmission}>
          Invite
        </Button>
      </div>
    </Modal>
  );
}

export default InviteModal;
