import React, { Component } from 'react';
import Icon1 from "images/icon1.png"
import Icon2 from "images/icon2.png"
import Icon3 from "images/icon3.png"
import Icon4 from "images/icon4.png"

class Pricing extends Component {
	render() {
		return (
			<section id="pricing" className="pricing-area ptb-100 bg-f9f9f9">
				<div className="container">
					<div className="section-title">
						<h1>OUR PRICING</h1>
						<br/>
						<p>INVEST IN YOURSELF NOW!</p>
						<br/>
						<p>
							Look through our listed offerings for the best package that suits your goals
						</p>
					</div>

					<div className="row justify-content-center">
						<div className="col-lg-3 col-md-6">
							<div className="pricing-table">
								<div className="pricing-header">
									<div className="icon">
										<img src={Icon1} alt="icon" />
									</div>

									<h3>Basic Plan</h3>
								</div>

								<ul className="pricing-features">
									<li className="active">Digital Splitsheets</li>
                  <li className="active">Ask MOTI</li>
									<li className="active">Music Meta-data Basic</li>
									<li className="unactive">Music Meta-data Advanced</li>
									<li className="unactive">Learning Material</li>
								</ul>

								<div className="price">
									$0
									<span>Per month</span>
								</div>

								<a href="/signup" className="btn btn-primary">Lets Go</a>
							</div>
						</div>

						<div className="col-lg-3 col-md-6">
							<div className="pricing-table active">
								<div className="pricing-header">
									<div className="icon">
										<img src={Icon2} alt="icon" />
									</div>

									<h3>Advanced Plan</h3>
								</div>

								<ul className="pricing-features">
								<li className="active">Digital Splitsheets</li>
                  <li className="active">Ask MOTI</li>
									<li className="active">Music Meta-data Basic</li>
									<li className="active">Music Meta-data Advanced</li>
									<li className="active">Learning Material</li>
								</ul>

								<div className="price">
									$0.00
									<span>Per month</span>
								</div>

								<a href="/signup" className="btn btn-primary">Let's Go</a>
							</div>
						</div>

						{/* <div className="col-lg-3 col-md-6">
							<div className="pricing-table">
								<div className="pricing-header">
									<div className="icon">
										<img src={Icon3} alt="icon" />
									</div>

									<h3>Expert Plan</h3>
								</div>

								<ul className="pricing-features">
									<li className="active">Digital Splitsheets</li>
									<li className="active">Learning Material</li>
									<li className="active">Ask MOTI</li>
								</ul>

								<div className="price">
									R150
									<span>Per month</span>
								</div>

								<a href="#" className="btn btn-primary">Select</a>
							</div>
						</div> */}

						<div className="col-lg-3 col-md-6">
							<div className="pricing-table">
								<div className="pricing-header">
									<div className="icon">
										<img src={Icon4} alt="icon" />
									</div>

									<h3>Download Free Resources Below</h3>
								</div>

								<p>Get access to splitsheets and draft contracts built for you by MOTI professionals</p>

								<a href="/signup" className="btn btn-primary">Sign Up Here</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Pricing;
