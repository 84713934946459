import React, { useEffect, useState } from 'react'
// Components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Videos from '../components/Videos';
import { getFromStorage } from '../helpers/appHelpers'
import { Redirect } from 'react-router-dom';

const FeaturesPage = (props) => {
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		const loggedInUser = getFromStorage('user')
		if (loggedInUser){
      setRedirect(true)
    }
    else {
			// do nothing
    }

	}, [])

	if (redirect) {
		return <Redirect push to="/dashboard" />
	}

	return (
		<div>
			<Navbar isDefaultSticky={true}/>
			<Videos />
			<Footer />
		</div>
	)
}

export default FeaturesPage