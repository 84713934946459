import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { getFromStorage } from '../helpers/appHelpers'
import { Redirect } from 'react-router-dom';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Select } from 'antd';
import { Button } from 'antd';
import { userRoles, userRolesMap } from '../utilities/config';
import {
  validateUsername,
  validateName,
  validateEmail,
  validatePassword
} from '../utilities/validators';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { setInStorage } from '../helpers/appHelpers'
import { getUser, updateUser } from '../services/userService'
import { createEditLink } from '../services/billingService'
import { LoadingOutlined } from '@ant-design/icons';
import Cards from 'react-credit-cards';
import { isPayingCustomer } from '../helpers/appHelpers'

const userProfilePage = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [role, setRole] = useState('')
  const [isRoleValid, setIsRoleValid] = useState(false)
  const [userName, setUserName] = useState('')
  const [isUserNameValid, setIsUserNameValid] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [isFirstNameValid, setIsFirstNameValid] = useState(false)
  const [lastName, setLastName] = useState('')
  const [isLastNameValid, setIsLastNameValid] = useState(false)
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [canSubmitForm, setCanSubmitForm] = useState(false)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [user, setUser] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [editLink, setEditLink] = useState('');
  const isSubscriptionActive = isPayingCustomer(user)

  const updateFrontEndUser = (user) => {
    setUser(user)
    setUserName(user.userName)
    setIsUserNameValid(true)
    setRole(user.role)
    setIsRoleValid(true)
    setFirstName(user.firstName)
    setIsFirstNameValid(true)
    setLastName(user.lastName)
    setIsLastNameValid(true)
    setEmail(user.emailAddress)
    setIsEmailValid(true)
    setIsPasswordValid(true)
  }

  useEffect(async () => {
    setPageLoading(true)
    const loggedInUser = getFromStorage('user')
    if (loggedInUser) {
      const response = await getUser({ userId: loggedInUser.id })
      if (response && response.ok) {
        const user = response.data
        setInStorage({
          key: 'user',
          value: user
        })
        updateFrontEndUser(user)
      }
      setPageLoading(false)
    }
    else {
      setPageLoading(false)
      setRedirect(true)
    }
  }, [
    user.firstName,
    user.lastName,
    user.role,
    user.emailAddress,
    user.password,
    user.userName
  ])

  useEffect(() => {
    const isFormValid =
      isRoleValid &&
      isUserNameValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isPasswordValid
    setCanSubmitForm(isFormValid)
  }, [isRoleValid,
    isUserNameValid,
    isFirstNameValid,
    isLastNameValid,
    isEmailValid,
    isPasswordValid
  ])

  useEffect(async () => {
    if (user && isSubscriptionActive && user.subscriptionId) {
      const response = await createEditLink({ subscriptionId: user.subscriptionId })
      if (response && response.status && response.data) {
        setEditLink(response.data.link)
      }
    }
  }, [user])

  function handleRole(value) {
    const isValid = userRoles.includes(value)
    setIsRoleValid(isValid)
    setRole(value)
  }

  function handleUserName(e) {
    const value = e.target.value
    const isValid = validateUsername(value)
    setIsUserNameValid(isValid)
    setUserName(value)
  }

  function handleFirstName(e) {
    const value = e.target.value
    const isValid = validateName(value)
    setIsFirstNameValid(isValid)
    setFirstName(value)
  }

  function handleLastName(e) {
    const value = e.target.value
    const isValid = validateName(value)
    setIsLastNameValid(isValid)
    setLastName(value)
  }

  function handleEmail(e) {
    const value = e.target.value
    const isValid = validateEmail(value)
    setIsEmailValid(isValid)
    setEmail(value)
  }

  function handlePassword(e) {
    const value = e.target.value
    const isValid = validatePassword(value)
    setIsPasswordValid(isValid)
    setPassword(value)
  }

  async function handleFormSubmission() {
    const formParams = {
      userId: user.id,
      ...(email ? { emailAddress: email.toLowerCase() } : {}),
      ...(password ? { password } : {}),
      ...(firstName ? { firstName: firstName.toLowerCase() } : {}),
      ...(lastName ? { lastName: lastName.toLowerCase() } : {}),
      ...(userName ? { userName: userName.toLowerCase() } : {}),
      ...(role ? { role } : {})
    }

    setIsFormLoading(true)
    const response = await updateUser(formParams)
    setIsFormLoading(false)

    if (response && response.ok) {
      const user = response.data
      setInStorage({
        key: 'user',
        value: user
      })
      updateFrontEndUser(user)
      confirmAlert({
        title: 'Success',
        message: 'User updated successfully!',
        buttons: [
          {
            label: 'Ok',
            onClick: () => { }
          }
        ]
      })
    }
    else {
      confirmAlert({
        title: 'Error',
        message: response.error,
        buttons: [
          {
            label: 'Ok',
            onClick: () => { }
          }
        ]
      })
    }
  }

  if (redirect) {
    return <Redirect push to="/" />
  }

  return (
    <div>
      <Navbar
        isDefaultSticky={true}
        isLoggedIn={true}
      />
      <section className="profile-area">
        <center>
          <h2 className="text-dark font-weight-bold mb-2"> My Profile</h2>
        </center>
        <br />
        <br />

        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 col-md-12">
            {pageLoading ?
              <LoadingOutlined style={{ fontSize: 24 }} spin />
              :
              <form>
                <div className="row">
                  {/* <div className="col-md-6">
                <div className="form-group">
                  <label>Username</label>
                  <Input style={isUserNameValid ? {} : {borderColor: '#B22222'}} value={userName} onChange={handleUserName} className="form-control" placeholder="" />
                  <p className='form-error-msg'>{!isUserNameValid ? 'Please enter a valid UserName' : ''}</p>
                </div>
              </div> */}

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Role</label>
                      <Select size={'large'} value={userRolesMap[role]} bordered={false} style={isRoleValid ? {} : { borderColor: '#B22222' }} defaultValue="" className="form-control" onChange={handleRole}>
                        <Option value="performing-artist">Performing Artist</Option>
                        <Option value="songwriter">Songwriter</Option>
                        <Option value="producer">Producer</Option>
                        <Option value="sound-engineer">Sound Engineer</Option>
                        <Option value="publisher">Publisher</Option>
                        <Option value="aggregator">Aggregator</Option>
                        <Option value="session-musician">Session Musician</Option>
                        <Option value="cd-manufacturer">CD Manufacturer</Option>
                        <Option value="booking-agent">Booking Agent</Option>
                        <Option value="road-manager">Road Manager</Option>
                        <Option value="business-manager">Business Manager</Option>
                        <Option value="promoter">Promoter</Option>
                        <Option value="tour-manager">Tour Manager</Option>
                        <Option value="videographer">Videographer</Option>
                        <Option value="photographer">Photographer</Option>
                        <Option value="public-relations">Public Relations</Option>
                        <Option value="disabled" disabled>
                          Disabled
                        </Option>
                      </Select>
                      <p className='form-error-msg'>{!isRoleValid ? 'Please enter a valid role' : ''}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name</label>
                      <Input style={isFirstNameValid ? {} : { borderColor: '#B22222' }} value={firstName} onChange={handleFirstName} className="form-control" placeholder="" />
                      <p className='form-error-msg'>{!isFirstNameValid ? 'Please enter a valid FirstName' : ''}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <Input style={isLastNameValid ? {} : { borderColor: '#B22222' }} value={lastName} onChange={handleLastName} className="form-control" placeholder="" />
                      <p className='form-error-msg'>{!isLastNameValid ? 'Please enter a valid LastName' : ''}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <Input style={isEmailValid ? {} : {borderColor: '#B22222'}} value={email} onChange={handleEmail} className="form-control" placeholder="" />
                  <p className='form-error-msg'>{!isEmailValid ? 'Please enter a valid Email' : ''}</p>
                </div>
              </div> */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Password</label>
                      <Input.Password
                        className="form-control"
                        style={isPasswordValid ? {} : { borderColor: '#B22222' }}
                        placeholder=""
                        onChange={handlePassword}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                      <p className='form-error-msg'>{!isPasswordValid ? 'Must be at least 6 characters' : ''}</p>
                    </div>
                  </div>
                </div>

                {/* <Link to="/dashboard" className="btn btn-primary">Signup</Link> */}
                <br />
                <Button disabled={!canSubmitForm} type="primary" shape="round" size={'large'} className="btn btn-primary atn-button btn-with-icon" loading={isFormLoading} onClick={handleFormSubmission}>
                  Update
                </Button>
                <br />
                <br />
              </form>
            }
          </div>
          <br />
          <br />
          {/* <center>
            <h2 className="text-dark font-weight-bold mb-2"> Billing</h2>
            <br />
            <p> You are currently paying using the below card details:</p>
            {
              editLink && user && user.billing && isSubscriptionActive ?
                <div>
                  <Cards
                    cvc={'***'}
                    expiry={`${user.billing.exp_month}${user.billing.exp_year}`}
                    name={`${user.firstName} ${user.lastName}`}
                    number={`${user.billing.bin}......${user.billing.last4}`}
                    issuer={user.billing.card_type}
                  />
                  <br />
                  <a href={editLink} target="_blank" className="">Manage Subscription</a>
                  <br />
                  <br />
                </div>
                :
                <div>
                  <h4> Please subscribe in order to manage your billing </h4>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
            }
          </center> */}
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default userProfilePage