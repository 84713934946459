import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import { Redirect } from 'react-router-dom';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import {
	validatePassword
} from '../utilities/validators';
import { resetPassword } from '../services/userService'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { setInStorage } from '../helpers/appHelpers';
import { getFromStorage } from '../helpers/appHelpers';
import queryString from 'query-string'

const ResetPasswordPage = (props) => {
	const [password, setPassword] = useState('')
	const [isPasswordValid, setIsPasswordValid] = useState(false)
	const [isFormLoading, setIsFormLoading] = useState(false)
	const [canSubmitForm, setCanSubmitForm] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [loggedInRedirect, setLoggedInRedirect] = useState(false)
  const [token, setToken] = useState('')

	useEffect(() => {
		const isFormValid = 
			isPasswordValid
		setCanSubmitForm(isFormValid)
	}, [isPasswordValid])

	useEffect(() => {
    const queryParams = queryString.parse(props.location.search)
    setToken(queryParams.token)
		const loggedInUser = getFromStorage('user')
		if (loggedInUser){
      setLoggedInRedirect(true)
    }
    else {
			// do nothing
    }
	}, [])

	function handlePassword(e) {
		const value = e.target.value
		const isValid = validatePassword(value)
		setIsPasswordValid(isValid)
		setPassword(value)
	}

	async function handleFormSubmission() {
		const formParams = {
			token: token,
			password: password
		}

		setIsFormLoading(true)
		const response = await resetPassword(formParams)
		setIsFormLoading(false)

		if (response && response.ok){
      confirmAlert({
				title: 'Success',
				message: 'Your password has been updated succesfully',
				buttons: [
					{
						label: 'Ok',
						onClick: () => {}
					}
				]
			})
			setRedirect(true)
		}
		else {
			confirmAlert({
				title: 'Error',
				message: response.error,
				buttons: [
					{
						label: 'Ok',
						onClick: () => {}
					}
				]
			})
		}
	}

	if (redirect) {
		return <Redirect push to="/signin" />
	}

	if (loggedInRedirect) {
		return <Redirect push to="/dashboard" />
	}

	return (
		<section className="login-area">
			<div className="d-table">
				<div className="d-table-cell">
					<div className="login-form">
						<h3>Reset Password</h3>

						<form>
							<div className="form-group">
								<label>New Password</label>
								<Input.Password
									className="form-control"
									style={isPasswordValid ? {} : {borderColor: '#B22222'}}
									placeholder=""
									onChange={handlePassword}
									iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
								/>
								<p className='form-error-msg'>{!isPasswordValid ? 'Must be at least 6 characters' : ''}</p>
							</div>

							<br/>
							<Button disabled={!canSubmitForm} type="primary" shape="round" size={'large'} className="btn btn-primary atn-button" loading={isFormLoading} onClick={handleFormSubmission}>
            		Reset
          		</Button>

							<p>
								<a href="/signup" className="pull-left">New? Create a new account</a>
								<a href="/signin" className="pull-right">Existing User? Signin</a>
							</p>
						</form>
					</div>
				</div>
			</div>
			< Footer/>
		</section>
	);
}

export default ResetPasswordPage;
