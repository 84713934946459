import { endpoints } from '../utilities/config'

async function createSplit(params) {
  const url = endpoints.splits.create
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })
  return response.json()
}

async function updateSplit(params) {
  const url = endpoints.splits.update
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(params),
  })
  return response.json()
}

async function acceptSplit(params) {
  const url = endpoints.splits.accept
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })
  return response.json()
}

async function getAllSplits(params) {
  const url = endpoints.splits.getAll.replace(':userUuid', params.userUuid)
  const response = await fetch(url, {
    method: 'GET'
  })
  return response.json()
}

async function emailSplitPdf(params) {
  const url = endpoints.splits.generatePdf
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  })
  return response.json()
}

export {
  createSplit,
  updateSplit,
  acceptSplit,
  getAllSplits,
  emailSplitPdf
}